import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  moveToFolder(e) {
    this.dispatch("copy", {
      detail: {
        qrId: Number(e.currentTarget.dataset.id),
        isPremium: Number(e.currentTarget.getAttribute('data-premium-folder-link')),
        folderName: e.currentTarget.getAttribute('data-folder-name-link')
      }
    });
  }
}
