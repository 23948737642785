import {CurrencyDTO} from "./currency_dto";
import axios from "axios";

export class CurrencyService
{
    _currencyCodeKey = 'code';
    _currencySignKey = 'sign';
    _currencyDecimalSeparatorKey = 'decimalSeparator';

    _currencyData = {};

    /**
     * @return {void}
     */
    loadCurrenciesData()
    {
        axios("/get-all-currencies").then(({ data }) => {
            this._currencyData = data;
        });
    }

    /**
     * @return {CurrencyDTO[]}
     */
    getAllCurrencies()
    {
        let currencyDtoArr = [];
        if (this._currencyData.length <= 0) {
            return currencyDtoArr;
        }

        for (const key in this._currencyData) {
            const currencyObject = this._currencyData[key];
            if (typeof currencyObject === 'object') {
                const currencyCode = currencyObject[this._currencyCodeKey];
                const currencySign = currencyObject[this._currencySignKey];
                const currencyDecimalSeparator = currencyObject[this._currencyDecimalSeparatorKey];

                if (
                    typeof currencyCode === 'string' &&
                    typeof currencySign === 'string' &&
                    typeof currencyDecimalSeparator === 'string'
                ) {
                    return null;
                }

                currencyDtoArr.push(new CurrencyDTO(currencyCode, currencySign, currencyDecimalSeparator));
            }
        }

        return currencyDtoArr;
    }

    /**
     * @param {string} currencyCode
     * @return {CurrencyDTO|null}
     */
    getCurrencyByCode(currencyCode)
    {
        const currencyDTO = this.getAllCurrencies().find(
            currencyDTO => currencyDTO.code === currencyCode
        );

        return currencyDTO !== undefined ? currencyDTO : null;
    }
}