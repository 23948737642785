import {Controller} from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
    static targets = ["noCards"];

    timer = null;

    connect() {
        this.cards = document.querySelectorAll(".accordion-item");
        this.searchInput = document.getElementById('searchBox');
        this.searchInput.addEventListener('keyup', this.handleSearch.bind(this));
    }

    handleSearch(e) {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            const csrfToken = document.querySelector('[name="_csrf_token"]').value;
            axios({
                method: 'get',
                url: '/faq',
                headers: {
                    'X-CSRF-TOKEN': csrfToken
                }
            });
            this.timer = null;
        }, 1000);

        const searchQuery = e.target.value.toLowerCase();

        for (let i = 0; i < this.cards.length; i++) {
            if (this.cards[i].textContent.toLowerCase().includes(searchQuery)) {
                this.cards[i].classList.remove("is-hidden");
            } else {
                this.cards[i].classList.add("is-hidden");
            }
        }

        const hiddenCards = document.querySelectorAll(".accordion-item.is-hidden");

        if (hiddenCards.length === this.cards.length) {
            this.noCardsTarget.classList.remove("d-none");
        } else {
            this.noCardsTarget.classList.add("d-none");
        }
    }
}