import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['tab', 'list', 'checkboxAll', 'menu']
    static classes = ['tab', 'tabActive', 'promocodeCheckbox']

    tabFilter(e)
    {
        let activeTab = this.element.getElementsByClassName(this.tabActiveClass)[0]
        activeTab.classList.remove(this.tabActiveClass)
        e.currentTarget.classList.add(this.tabActiveClass)
        this.loadPromocodes(e.params.filter)
    }

    loadPromocodes(filter)
    {
        let uri = window.location.pathname.split('?')[0]
        history.pushState({}, null, uri)
        fetch(uri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({tabFilter: filter})
        })
            .then(response => response.text())
            .then(html => this.listTarget.innerHTML = html)
    }

    edit(e)
    {
        if (!e.target.classList.contains(this.promocodeCheckboxClass)) {
            e.preventDefault()
            window.location.href = e.params.url
        }
    }

    checkAll(e)
    {
        let allCheckbox = this.listTarget.querySelectorAll('.' + this.promocodeCheckboxClass)
        let isChecked = !!e.currentTarget.checked;
        allCheckbox.forEach(checkbox => {
            checkbox.checked = isChecked
        })
    }

    delete(e)
    {
        e.preventDefault()
        let allCheckbox = this.listTarget.querySelectorAll('.' + this.promocodeCheckboxClass)
        let toDelete = []
        allCheckbox.forEach(checkbox => {
            if (checkbox.checked) {
                toDelete.push(checkbox.dataset.id)
            }
        })
        if (toDelete.length === 0) {
            alert('Промокод не выбран')
        } else {
            fetch(e.params.url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({ids: toDelete})
            })
                .then(response => response.json())
                .then(response => {
                    let activeTab = this.element.getElementsByClassName(this.tabActiveClass)[0]
                    let filter = activeTab.dataset.filter
                    this.loadPromocodes(filter)
                    let tabs = this.menuTarget.getElementsByClassName(this.tabClass)
                    for (let tab of tabs) {
                        let filter = tab.dataset.filter
                        if (filter in response.count) {
                            let numberTag = tab.getElementsByTagName('span')[0]
                            numberTag.textContent = response.count[filter]
                        }
                    }
                })
        }
    }
}
