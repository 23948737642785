import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    newHome: Boolean,
  }
  gtagClick(e) {
    if (e.params.label === "appstore") {
      dataLayer.push({
        event: `${this.newHomeValue ? "new-" : ""}button_get_on_appstore`,
      });
    }
    if (e.params.label === "googleplay") {
      dataLayer.push({
        event: `${this.newHomeValue ? "new-" : ""}button_get_on_google_play`,
      });
    }
    let page = e.view.window.location.pathname.replace(/^\//g, "");
    if (page === "") {
      page = "home";
    }
    const event = `${this.newHomeValue ? "new-" : ""}${page}/${e.params.type}/${
      e.params.label
    }`;
    gtag("event", event, {
      event_category: page,
      event_label: event,
      value: 1,
    });
  }
}
