import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["messengerSelect", "messengerData", "museumForm"];

  connect() {
    this.updateMessengerDataState();
  }

  updateMessengerDataState() {
    this.messengerSelectTarget.addEventListener("change", () => {
      this.messengerDataTarget.disabled = this.messengerSelectTarget.value === "";
    });
  }

  showMessage(message, type = "danger") {
    const errorMessages = document.getElementById("form-messages");

    errorMessages.innerHTML = `<div class="alert alertMsg alert-${type}">${message}</div>`;

    setTimeout(() => {
      errorMessages.innerHTML = "";
    }, 3000);
  }

  submitForm(e) {
    e.preventDefault();

    if (!this.museumFormTarget.checkValidity()) {
      this.showMessage("Please fill out the required fields.");
      return;
    }

    axios({
      method: "post",
      url: "/qr-museum",
      data: new FormData(this.museumFormTarget),
      headers: { "X-Requested-With": "XMLHttpRequest" }
    })
      .then(response => {
        if (response.data.status === "success") {
          const myModalEl = document.getElementById("exampleModal");
          const myModal = new Modal(myModalEl);
          myModal.show();

          setTimeout(() => {
            myModal.hide();
          }, 3000);

          this.museumFormTarget.reset();
        } else {
          this.showMessage(response.data.message);
        }
      })
      .catch(error => {
        this.showMessage("An error occurred.");
        console.error(error);
      });
  }
}