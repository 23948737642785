import { Controller } from "@hotwired/stimulus";
import { webView } from "../../variables/webView";

const countMaxTokens = 3;

export default class extends Controller {
  static targets = [
    "list",
    "alertMessage",
    // "progressBar",
    // "progressBarCreatedNum",
    // "progressBarMaxNum",
    "alert",
    "temporaryKeyAlert",
    "createApiBtn",
    "buyPremiumBtn",
    "keyExpiredAlert",
    "howWorksLink",
  ];

  static values = {
    premium: Boolean,
  };

  connect() {
    if (webView) {
      this.howWorksLinkTarget.remove();
    }
    this.loadTokens();
    // this.loadCountQrCode();
  }

  copyToken(e) {
    let keyElement = e.currentTarget;
    let key = keyElement.textContent;
    navigator.clipboard.writeText(key).then(() => {
      let tooltip = keyElement.nextElementSibling;
      tooltip.style.opacity = "1";
      setTimeout(function () {
        tooltip.style.opacity = "0";
      }, 2000);
    });
  }

  deleteToken(e) {
    let currentElement = e.currentTarget;
    fetch(e.params.urlDelete, {
      method: "POST",
      body: JSON.stringify({ tokenId: e.params.tokenId }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          currentElement.closest("tr").remove();
          if (!this.listTarget.firstElementChild.tBodies[0].rows.length) {
            this.loadTokens();
          }
          if (this.premiumValue) return;
          this.createApiBtnTarget.classList.remove("d-none");
          this.buyPremiumBtnTarget.classList.add("d-none");
          this.temporaryKeyAlertTarget.classList.remove("d-none");
        }
      });
  }

  createToken(e) {
    let tableList = this.listTarget.firstElementChild;
    if (
      tableList.tBodies &&
      tableList.tBodies[0].rows.length >= countMaxTokens
    ) {
      let alertMessage = this.alertMessageTarget;
      alertMessage.style.display = "block";
      setTimeout(function () {
        alertMessage.style.display = "none";
      }, 3000);
    } else {
      const formData = new FormData();
      const locate = document
        .querySelector("[data-locale]")
        .getAttribute("data-locale");
      formData.append("_locale", locate);
      fetch(e.params.urlCreate, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          let alert = this.alertTarget;
          if (response.status === "success") {
            this.loadTokens();
            alert.classList.remove("d-none");
            alert.innerHTML = response.message;
            setTimeout(() => this.alertTarget.classList.add("d-none"), 9000);
          }else if(response.status === "error"){
            alert.classList.remove("d-none");
            alert.innerHTML = response.error;
            setTimeout(() => this.alertTarget.classList.add("d-none"), 9000);
          }
        });
    }
  }

  async loadTokens() {
    const tableList = this.listTarget;
    await fetch(this.element.dataset.urlLoad, {
      method: "POST",
    })
      .then((response) => response.text())
      .then((html) => (tableList.innerHTML = html));
    if (tableList.firstElementChild.tBodies && !this.premiumValue) {
      this.createApiBtnTarget.classList.add("d-none");
      this.buyPremiumBtnTarget.classList.remove("d-none");
      document.querySelector(".delete_key").remove();
    }

    if (this.premiumValue) return;

    const expireDate = document.querySelector(".expire_date");
    if (
      expireDate &&
      new Date(expireDate.innerHTML.split(".").reverse().join("-")) < new Date()
    ) {
      expireDate.innerHTML = `<small class="px-2 py-1 bg-danger text-danger rounded bg-opacity-10">${expireDate.dataset.expired}<small>`;
      this.keyExpiredAlertTarget.classList.remove("d-none");
    } else {
      this.temporaryKeyAlertTarget.classList.remove("d-none");
    }
  }

  // loadCountQrCode() {
  //   fetch(this.element.dataset.urlCountQrCode, {
  //     method: "POST",
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       let created = response.created;
  //       let max = this.progressBarMaxNumTarget.textContent;
  //       let createdPercent = (created / max) * 100;
  //       let progressBar = this.progressBarTarget;
  //       let width = 1;
  //       let idLine = setInterval(frame, 20);
  //       function frame() {
  //         if (width >= createdPercent) {
  //           clearInterval(idLine);
  //         } else {
  //           width++;
  //           progressBar.style.width = width + "%";
  //         }
  //       }
  //       let num = 0;
  //       let createdNum = this.progressBarCreatedNumTarget;
  //       let idNum = setInterval(frameNum, 20);
  //       let step = created / createdPercent;
  //       step = Math.ceil(step);
  //       function frameNum() {
  //         if (num >= created) {
  //           createdNum.textContent = created.toString();
  //           clearInterval(idNum);
  //         } else if (step > created) {
  //           createdNum.textContent = created.toString();
  //           clearInterval(idNum);
  //         } else {
  //           createdNum.textContent = num;
  //           num = num + step;
  //         }
  //       }
  //     });
  // }

  closeAlert(e) {
    e.currentTarget.parentNode.classList.add("d-none");
  }
}
