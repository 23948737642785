import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  createCopyBtn(e) {
    this.dispatch("copy", {
      detail: {
        qrId: Number(e.currentTarget.dataset.id),
        qrName: e.currentTarget.dataset.qrName,
      },
    });
  }
}
