import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  uniqueUrl(e) {
    this.dispatch("uniqueUrlBtn", {
      detail: {
        deleteUrl: e.currentTarget.dataset.deleteUrl,
        link: e.currentTarget.dataset.link,
        updateUrl: e.currentTarget.dataset.updateUrl,
      },
    });
  }
}
