import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.saveScrollPosition();
        this.restoreScrollPosition();
    }

    saveScrollPosition() {
        document.querySelector('form').addEventListener('submit', () => {
            localStorage.setItem('formSubmitted', 'true');
        });

        window.addEventListener("beforeunload", () => {
            localStorage.setItem("scrollPosition", window.pageYOffset.toString());
        });
    }

    restoreScrollPosition() {
        window.addEventListener("load", () => {
            const scrollPosition = localStorage.getItem("scrollPosition");
            const formSubmitted = localStorage.getItem("formSubmitted");

            if (scrollPosition !== null && formSubmitted === 'true') {
                window.scrollTo(0, scrollPosition);
                localStorage.removeItem("scrollPosition");
                localStorage.removeItem("formSubmitted");
            }
        });
    }
}