import axios from "axios";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "closeCreateModal",
    "inputCreateErr",
    "folderDublicateErr",
  ];

  async create() {
    if (!this.inputTarget.value.trim().length) {
      this.showErrorMsg(this.inputCreateErrTarget);
      return;
    }
    const formData = new FormData();
    formData.append("name", this.inputTarget.value);
    try {
      const { data } = await axios.post("/folder/new", formData);
      if (data.error) {
        this.showErrorMsg(this.folderDublicateErrTarget);
        return;
      }
      this.closeCreateModalTarget.click();
      window.location.reload();
    } catch (err) {}
  }

  showErrorMsg(target) {
    target.classList.remove("d-none");
    setTimeout(() => target.classList.add("d-none"), 5000);
  }
}
