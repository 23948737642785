import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["alert"];

    connect() {
        this.alertTargets.forEach((alertElement) => {
            if (alertElement.textContent.trim() !== "") {
                this.hideAlertMessage(alertElement, 10000);
            }
        });
    }

    hideAlertMessage(alertElement, delay) {
        if (!alertElement) {
            return;
        }

        alertElement.style.display = "block";
        setTimeout(() => {
            alertElement.style.display = "none";
        }, delay);
    }
}
