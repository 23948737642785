import { Controller } from "@hotwired/stimulus";
import { webView } from "../../../assets/variables/webView";
export default class extends Controller {
  static values = {
    newHome: Boolean,
  }
  
  connect() {
    this.checkNeedLogoutWhen2Fa();
    this.checkWebView();
    function setCookie(cName, cValue, expDays) {
      let date = new Date();
      date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
      const expires = "expires=" + date.toUTCString();
      document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
    }

    document.querySelectorAll(".lang").forEach(function (el) {
      el.addEventListener("click", function (e) {
        e.preventDefault();
        setCookie("user_local", e.currentTarget.hreflang, 360);
        window.location.href = e.currentTarget.href;
      });
    });
  }

  gtagClick(e) {
    if (e.params.label === "create_qr_code") {
      dataLayer.push({
        event: `${
          this.newHomeValue ? "new-" : ""
        }button_create_qr_code_general`,
      });
    }
    if (e.params.label === "qr_scanner") {
      dataLayer.push({
        event: `${this.newHomeValue ? "new-" : ""}button_QR_Scanner`,
      });
    }
    let page = e.view.window.location.pathname.replace(/^\//g, "");
    if (page === "") {
      page = "home";
    }
    const event = `${this.newHomeValue ? "new-" : ""}${page}/${e.params.type}/${
      e.params.label
    }`;
    gtag("event", event, {
      event_category: page,
      event_label: event,
      value: 1,
    });
  }

  loginBtn() {
    dataLayer.push({ event: `${this.newHomeValue ? "new-" : ""}button_Login` });
  }

  createNewBtn() {
    dataLayer.push({
      event: `${this.newHomeValue ? "new-" : ""}button_Create_New_QR_Code`,
    });
  }

  checkWebView() {
    if (webView) {
      document.querySelector("#logo-image")?.removeAttribute("href");
      ["faq", "userDomain", "importCsv", "login", "language"].forEach((item) =>
        document.querySelectorAll(`#${item}`).forEach((item) => item.remove())
      );
    }
  }

  checkNeedLogoutWhen2Fa() {
    if (window.location.pathname === "/2fa") {
      let timerId = setInterval(() => {
        if (window.location.pathname === "/2fa") {
          const form = document.querySelector("[data-login-time]");
          if (form) {
            let userLoginTime = form.getAttribute("data-login-time");
            let currentTime = Date.now();
            if (userLoginTime) {
              userLoginTime = Date.parse(userLoginTime);
              if (userLoginTime < currentTime) {
                window.location.href = "/logout";
              }
            }
          }
        } else {
          clearInterval(timerId);
        }
      }, 60000);
    }
  }
}
