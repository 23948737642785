import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ['form', 'mdc', 'allProducts', 'product', 'emailFieldInput', 'messageError', 'messageSuccess'];

    connect()
    {
        this.mdcTargets.forEach((element) => {
            new mdc.textField.MDCTextField(element);
        });
    }

    send(e)
    {
        e.preventDefault();
        let formValidateEvent = this.dispatch('send', {detail: {target: e.currentTarget}})
        if (formValidateEvent.detail.isValid) {
            let formData = new FormData(this.formTarget);
            this.appendEmails(formData);

            fetch(e.params.url, {
                method: 'POST',
                body: formData
            })
                .then(response => response.json())
                .then((response) => {
                    if (response.status === 'success') {
                        this.messageSuccessTarget.style.display = 'block';
                        setTimeout(function () {
                            window.location.href = response.url
                        }, 2000);
                    } else {
                        this.messageErrorTarget.style.display = 'block';
                    }
                });
        }
    }

    delete(e)
    {
        e.preventDefault()
        if (confirm('Вы уверены что хотите удалить промокод?')) {
            let promocodeId = e.params.id
            fetch(e.params.url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({ids: [promocodeId]})
            })
                .then(response => response.json())
                .then((response) => {
                    if (response.status === 'success') {
                        this.messageSuccessTarget.firstElementChild.textContent = 'Промокод успешно удален'
                        this.messageSuccessTarget.style.display = 'block';
                        setTimeout(function () {
                            window.location.href = response.redirectUrl
                        }, 2000);
                    } else {
                        this.messageErrorTarget.style.display = 'block';
                    }
                });
        }
    }

    appendEmails(formData)
    {
        if (this.emailFieldInputTarget.textContent.trim().length !== 0) {
            let emails = this.emailFieldInputTarget.textContent.trim();
            emails = emails.split(/(\s+)/);
            let clearEmails = [];
            emails.forEach(email => {
                if (email.trim().length !== 0) {
                    clearEmails.push(email);
                }
            });
            formData.append('emails', JSON.stringify(clearEmails));
        }
    }

    markProducts(e)
    {
        e.preventDefault();
        let input = e.currentTarget.getElementsByTagName('input')[0];
        if (input.value === '0') {
            let isCheck = input.checked = input.checked !== true;
            this.productTargets.forEach((product) => {
                let currentInput = product.getElementsByTagName('input')[0];
                currentInput.checked = isCheck;
            });
        } else {
            input.checked = input.checked !== true;
            this.productTargets.forEach((product) => {
                let currentInput = product.getElementsByTagName('input')[0];
                if (currentInput.value === '0') {
                    currentInput.checked = false;
                }
            });
        }
    }

    toggleAllProducts() {
        const check = this.allProductsTarget.checked;
        this.productTargets.forEach((checkbox) => {
            checkbox.checked = check;
        });
    }

    checkIndividualProduct() {
        const allChecked = this.productTargets.every((checkbox) => checkbox.checked);
        const allCheckbox = this.allProductsTarget;

        allCheckbox.checked = allChecked;
    }

    validateProductSelection(event) {
        const isSelected = this.productTargets.some(input => input.checked);

        if (!isSelected) {
            alert('You must select at least one product');
            event.preventDefault();
        }
    }
}