import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import getLocale from "../utils/getLocale";
import { Modal } from "bootstrap";
import debounce from "debounce";

export default class extends Controller {
  static targets = ["email", "shareBtn", "addBtn", "alertError", "emailError", "role", "deleteShareUserBtn"];
  users = [];
  newUsers = [];
  countCloneEmailInput = 1;
  deleteShareUser = new Modal("#deleteShareUser");

  async connect() {
    this.emailInput = await debounce(this.emailInput.bind(this), 500);
  }

  async emailInput(e) {
    let target = e.target;
    this.shareBtnTarget.setAttribute("disabled", "");
    let email = e.target.value.trim();
    let parentElement = target.closest(".input_preparation");
    let errorDivDuplicate = parentElement.querySelector(".emailErrorDuplicate");
    let errorDiv = parentElement.querySelector("[data-admin--share-folder-target=\"emailError\"]");

    if (email === "") {
      if (errorDivDuplicate.classList.contains("d-none") === false) {
        errorDivDuplicate.classList.add("d-none");
      }

      if (errorDiv.classList.contains("d-none") === false) {
        errorDiv.classList.add("d-none");
      }
      let emails = document.querySelectorAll("[data-action=\"input->admin--share-folder#emailInput\"]");
      if (emails.length > 1) {
        this.shareBtnTarget.removeAttribute("disabled");
      }
      this.addBtnTarget.setAttribute("disabled", "");
      return false;
    }

    try {
      let currenEmail = e.target.value;
      let validateEmail = currenEmail.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );

      if (validateEmail == null) {
        this.showErrorMessage(errorDiv);
        this.shareBtnTarget.setAttribute("disabled", "");
        this.addBtnTarget.setAttribute("disabled", "");
        if (errorDivDuplicate.classList.contains("d-none") === false) {
          errorDivDuplicate.classList.add("d-none");
        }
        return true;
      }

      this.emailErrorTarget.classList.add("d-none");

      let emails = document.querySelectorAll("[data-action=\"input->admin--share-folder#emailInput\"]");
      let countDuplicateEmail = 0;
      emails.forEach((item) => {
        if (item.value.trim() === email) {
          countDuplicateEmail += 1;
        }
      });

      let sharedUserEmails = document.querySelectorAll("[data-useremail]");
      sharedUserEmails.forEach((item) => {
        if (item.getAttribute("data-useremail") === email) {
          countDuplicateEmail = 2;
        }
      });

      if (errorDivDuplicate.classList.contains("d-none") === false) {
        errorDivDuplicate.classList.add("d-none");
        this.addBtnTarget.removeAttribute("disabled");
        this.shareBtnTarget.removeAttribute("disabled");
      }

      if (countDuplicateEmail > 1) {
        errorDivDuplicate.classList.remove("d-none");
        if (errorDiv.classList.contains("d-none") === false) {
          errorDiv.classList.add("d-none");
        }
        this.addBtnTarget.setAttribute("disabled", "");
        this.shareBtnTarget.setAttribute("disabled", "");
      } else {
        this.addBtnTarget.removeAttribute("disabled");
        this.shareBtnTarget.removeAttribute("disabled");
      }
    } catch (e) {
      this.showErrorMessage(errorDiv);
      this.shareBtnTarget.setAttribute("disabled", "");
      this.addBtnTarget.setAttribute("disabled", "");
      if (errorDivDuplicate.classList.contains("d-none") === false) {
        errorDivDuplicate.classList.add("d-none");
      }
    }
  }

  showErrorMessage(alertItem) {
    alertItem.classList.remove("d-none");
  }

  async shareFolder(e) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const folderId = urlSearchParams.get("folderId");
    const formData = new FormData();
    formData.append("folderId", folderId);
    formData.append("_locale", document.querySelector("[data-locale]").getAttribute("data-locale"));

    let emails = document.querySelectorAll(".input_preparation");
    emails.forEach((input) => {
      let oneEmail = input.querySelector("#floatingInput").value.trim();
      let role = input.querySelector("#role").value;
      if (oneEmail !== "") {
        formData.append("emails[]", JSON.stringify({ "email": oneEmail, "role": role, "change": 0 }));
      }
    });

    let changeRoles = document.querySelectorAll("[data-change-role]");

    changeRoles.forEach((item) => {
      let oneEmail = item.querySelector("[data-userEmail]").getAttribute("data-userEmail");
      let role = item.querySelector("#role").value;
      formData.append("emails[]", JSON.stringify({ "email": oneEmail, "role": role, "change": 1 }));
    });

    try {
      await axios.post(`/entry/share/folder`, formData);
      location.reload();
    } catch (e) {
      if (e.response.data?.error) {
        this.showErrorAlert(e.response.data.error);
      }
    }
  }

  async addSharedUser() {
    if (this.countCloneEmailInput >= 5) {
      return false;
    }
    let div = document.querySelector(".input_preparation");
    let p_prime = div.cloneNode(true);
    p_prime.querySelector(".buttonDiv").style.visibility = "hidden";
    p_prime.querySelector("input").value = "";
    const divs = document.querySelectorAll(".input_preparation");
    divs[divs.length - 1].after(p_prime);
    this.countCloneEmailInput += 1;
    this.addBtnTarget.setAttribute("disabled", "");
  }

  changeRole(e) {
    let target = e.target;
    let parentElement = target.closest("tr");

    if (parentElement.getAttribute("data-change-role")) {
      parentElement.removeAttribute("data-change-role");
    } else {
      parentElement.setAttribute("data-change-role", "1");
    }

    let changeRoles = document.querySelectorAll("[data-change-role]");

    if (changeRoles.length === 0) {
      this.shareBtnTarget.setAttribute("disabled", "");
    } else {
      this.shareBtnTarget.removeAttribute("disabled");
    }
  }

  openRemoveModal(e) {
    e.preventDefault();
    let target = e.target;
    let href = target.closest("a");
    this.deleteShareUser.show();

    if (href.classList.contains("opacity-50") === true) {
      return false;
    }
    let questionElement = document.querySelector(".question");
    let questionArray = questionElement.innerHTML.split(",");
    let folder = document.querySelector("[data-ahsred-folder-name]").getAttribute("data-ahsred-folder-name");
    let locale = document.querySelector("[data-locale]").getAttribute("data-locale");
    let question = questionArray[0];
    question += " " + folder;
    question += questionArray[1];

    if (locale === "he" || locale === "ar") {
      question = questionArray[1];
      question += " " + folder;
      question += questionArray[0];
    }

    questionElement.innerHTML = question;
    let deleteBtn = document.querySelector("#deleteShareUserBtn");
    deleteBtn.setAttribute("href", href.getAttribute("href"));
  }

  showErrorAlert(message) {
    this.alertErrorTarget.classList.remove("d-none");
    const errorMessage = this.alertErrorTarget.querySelector(".error");
    errorMessage.innerHTML = message;
    setTimeout(() => {
      this.alertErrorTarget.classList.add("d-none");
      errorMessage.innerHTML = "";
    }, 5000);
  }

  closeAlert(e) {
    e.currentTarget.parentNode.classList.add("d-none");
  }
}
