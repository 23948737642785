import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  customUrl(e) {
    const hideDelete = e.currentTarget.dataset.hideDelete
      ? { hideDelete: e.currentTarget.dataset.hideDelete }
      : null;
    this.dispatch("customUrlBtn", {
      detail: {
        deleteUrl: e.currentTarget.dataset.deleteUrl,
        link: e.currentTarget.dataset.link,
        updateUrl: e.currentTarget.dataset.updateUrl,
        ...hideDelete,
      },
    });
  }
}
