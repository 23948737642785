import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["noCards"];
  cards = document.querySelectorAll(".accordion-item");
  timer = null;

  search(e) {

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      const csrfToken = document.querySelector('[name="_csrf_token"]').value;
      axios({
        method: 'get',
        url: '/entry/faq',
        headers: {
          'X-CSRF-TOKEN': csrfToken
        }
      })
      this.timer = null;
    }, 1000);

    for (let i = 0; i < this.cards.length; i++) {
      if (
        this.cards[i].textContent
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        this.cards[i].classList.remove("is-hidden");
      } else {
        this.cards[i].classList.add("is-hidden");
      }
    }
    if (
      document.querySelectorAll(".accordion-item.is-hidden").length ===
      this.cards.length
    ) {
      this.noCardsTarget.classList.remove("d-none");
    } else {
      this.noCardsTarget.classList.add("d-none");
    }
  }
}
