import axios from "axios";
import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  folderModal = new Modal("#createFolderModal");
  static values = {
    logged: String,
  };

  static targets = [
    "oldFolderName",
    "inputFolderName",
    "closeRenameModal",
    "folderItem",
    "inputCreateErr",
    "deleteFolderName",
    "deleteFolderBtn",
  ];
  coockieFolderId = document.cookie
    .split("; ")
    .find((item) => item.includes("folderId"))
    ?.split("=")[1];

  connect() {
    this.folderItemTargets.forEach((item) => {
      if (item.dataset.folderId == this.coockieFolderId) {
        item.classList.toggle("active-folder");
        item.querySelector("#folderIc").href.baseVal =
          "/static/pages/admin-img/symbol.svg#icon-open-folder";
      }
    });
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("archived")) {
      this.folderItemTargets.forEach((item) => item.classList.add("d-none"));
    }
  }

  getFolderName(e) {
    this.oldFolderNameTarget.innerHTML = e.currentTarget.dataset.folder;
    this.oldFolderNameTarget.dataset.folderId =
      e.currentTarget.dataset.folderId;
  }

  renameFolder() {
    if (!this.inputFolderNameTarget.value.trim().length) {
      this.inputCreateErrTarget.classList.remove("d-none");
      setTimeout(() => this.inputCreateErrTarget.classList.add("d-none"), 5000);
      return;
    }
    const formData = new FormData();
    formData.append("name", this.inputFolderNameTarget.value);
    try {
      axios
        .post(
          `/folder/edit/${this.oldFolderNameTarget.dataset.folderId}`,
          formData
        )
        .then(() => {
          this.closeRenameModalTarget.click();
          window.location.reload();
        });
    } catch (err) {}
  }

  deleteFolder(e) {
    this.deleteFolderNameTargets.forEach(
      (item) => (item.innerHTML = e.currentTarget.dataset.folderName)
    );
    this.deleteFolderBtnTarget.dataset.folderId =
      e.currentTarget.dataset.folderId;
  }

  deleteFolderBtn(e) {
    e.preventDefault();
    try {
      axios
        .post(`/folder/delete/${e.currentTarget.dataset.folderId}`)
        .then(() => {
          document.cookie =
            "folderId" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          window.location.reload();
        });
    } catch (err) {}
  }

  openFolder(e) {
    const cuttentFolderId = e.currentTarget.dataset.folderId;
    const href = e.currentTarget.dataset.href;
    if (
      !(
        e.currentTarget === e.target ||
        e.target.id === "dataFolder" ||
        e.target.parentElement.id === "dataFolder"
      )
    ) {
      return;
    }
    const preloader = document.querySelector(".loaderWrapper");
    preloader.classList.remove("d-none");
    axios(`/entry/filterByFolder/${cuttentFolderId}`)
      .then(() => {
        document.cookie =
          "filterByType" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        window.location.href = href;
      })
      .catch(() => preloader.classList.add("d-none"));
  }

  closeFolder() {
    document.cookie =
      "folderId" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  createFolderModal(e) {
    if (this.loggedValue.length) {
      this.folderModal.show();
    } else {
      window.location.href = e.currentTarget.dataset.login;
    }
  }

  closeModal() {
    this.folderModal.hide();
  }

  downloadFolderInit(e) {
    const downloadFolderBtn = document.querySelector("#downloadFolderBtn");
    if (!downloadFolderBtn) {
      return;
    }
    downloadFolderBtn.dataset.folderId = e.target.dataset.folderId;
    downloadFolderBtn.dataset.folder = e.target.dataset.folder;
  }
}
