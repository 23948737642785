import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["closeModal", "qrName"];
  storageIsFullModal = null;
  modal = null;

  init(e) {
    document.querySelector("#qrIdCopy").dataset.qr = e.detail.qrId;
    document.querySelector("#qrNameModal").innerHTML = e.detail.qrName;
  }

  async connect() {
    this.modal = (await import("bootstrap")).Modal;
    this.storageIsFullModal = new this.modal("#storage-size-exceeded");
  }

  getCookie(cookieName) {
    let cookie = {};
    document.cookie.split(";").forEach(function (el) {
      let [key, value] = el.split("=");
      cookie[key.trim()] = value;
    });
    return cookie[cookieName];
  }

  async createCopyModal(e) {
    let qrId = e.currentTarget.dataset.qr;
    let locale = document
      .querySelector("[data-locale]")
      .getAttribute("data-locale");
    if (locale === undefined) {
      locale = "en";
    }

    try {
      let { data } = await axios.get(`/entry/${qrId}/check/storage`);
      if (data.status === "error") {
        if (data.error === "Not enough space") {
          this.closeModalTarget.click();
          this.storageIsFullModal.show();
          return false;
        } else if (data.error === "Authorization") {
          window.location.href = "/login";
          return false;
        }
      }
      window.location.href = `${
        locale !== "en" ? `/${locale}` : ""
      }/entry/${qrId}/copy`;
      this.closeModalTarget.click();
    } catch (e) {}
  }
}
