import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import getLocale from "../utils/getLocale";

export default class extends Controller {
  static targets = ["changeOwnerBtn", "email", "alertError", "emailError"];

  async emailInput(e) {
    const formData = new FormData();
    formData.append("email", e.target.value);
    let locale = document.querySelector('[data-locale]').getAttribute('data-locale')
    formData.append('locale', locale)
    try {
      const { data } = await axios.post("/entry/email_exists", formData);
      if (data.error) {
        this.showErrorMessage(this.emailErrorTarget);
        this.changeOwnerBtnTarget.setAttribute("disabled", "");
      } else {
        this.emailErrorTarget.classList.add("d-none");
        this.changeOwnerBtnTarget.removeAttribute("disabled");
      }
    } catch (e) {
      this.showErrorMessage(this.emailErrorTarget);
      this.changeOwnerBtnTarget.setAttribute("disabled", "");
    }
  }

  showErrorMessage(alertItem) {
    alertItem.classList.remove("d-none");
  }

  async moveFolder(e) {
    let locale = document.querySelector('[data-locale]').getAttribute('data-locale')

    const formData = new FormData();
    formData.append("folderId", e.target.dataset.id);
    formData.append("email", this.emailTarget.value);
    formData.append('_locale', locale)

    try {
      await axios.post(`/entry/folder/change_owner`, formData);
      window.location.href = e.target.dataset.href;
    } catch (e) {
      if (e.response.data?.error) {
        this.showErrorAlert(e.response.data.error);
      }
    }
  }

  showErrorAlert(message) {
    this.alertErrorTarget.classList.remove("d-none");
    const errorMessage = this.alertErrorTarget.querySelector(".error");
    errorMessage.innerHTML = message;
    setTimeout(() => {
      this.alertErrorTarget.classList.add("d-none");
      errorMessage.innerHTML = "";
    }, 5000);
  }

  closeAlert(e) {
    e.currentTarget.parentNode.classList.add("d-none");
  }
}
