import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['required']
    static classes = ['mdcFieldInvalid']

    validate(event)
    {
        let detail = event.detail
        let target, currentType
        target = detail !== 0 ? detail.target : event.currentTarget
        currentType = target.getAttribute('name')
        let isValid = true
        for (let element of this.requiredTargets) {
            if ((currentType === 'submit' && element.hasAttribute('required'))
                || target === element
                || element.value.trim().length !== 0) {
                isValid = this.validateElement(element)
                if (!isValid) {
                    break
                }
            }
        }
        if (detail !== 0) {
            detail.isValid = isValid
        }
    }

    validateElement(element)
    {
        let isValid = true
        let type = element.getAttribute('name')
        let regEx, message
        let value = element.value.trim()
        switch (type) {
            case 'code':
                regEx = /^[A-Za-z\d]{0,10}$/
                message = 'Только буквы цифры не больше 10 символов'
                break
            case 'value':
            case 'limit':
                regEx = /^\d+$/
                message = 'Только цифры'
                break
            case 'expiration-date':
                regEx = null
                message = ''
                break
            default:
                break
        }


        let errorMessage = element.parentElement.nextElementSibling

        if (value.length !== 0 && regEx !== null && !regEx.test(value)) {
            element.parentElement.classList.add(this.mdcFieldInvalidClass)
            if (message) {
                errorMessage.textContent = message
                errorMessage.style.display = 'block'
            }
            isValid = false
        } else if (value.length === 0) {
            if (element.hasAttribute('required')) {
                element.parentElement.classList.add(this.mdcFieldInvalidClass)
            }
            if (errorMessage !== null) {
                errorMessage.style.display = 'none'
            }
            isValid = false
        } else {
            if (errorMessage !== null) {
                errorMessage.style.display = 'none'
            }
        }

        return isValid
    }
}