export class CurrencyDTO
{
    /**
     * @private
     * @property {string}
     */
    _code;

    /**
     * @private
     * @property {string}
     */
    _symbol;

    /**
     * @private
     * @property {number}
     */
    _decimalSeparator;

    /**
     * @param {string} code
     * @param {string} symbol
     * @param {number} decimalSeparator
     */
    constructor(code, symbol, decimalSeparator) {
        this._code = code;
        this._symbol = symbol;
        this._decimalSeparator = decimalSeparator;
    }

    /**
     * @return {string}
     */
    get code() {
        return this._code;
    }

    /**
     * @return {string}
     */
    get symbol() {
        return this._symbol;
    }

    /**
     * @return {number}
     */
    get decimalSeparator() {
        return this._decimalSeparator;
    }
}