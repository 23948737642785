import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import { webView } from "../../../variables/webView";
import { getBase64FromUrl } from "../../../hooks/helpers";

export default class extends Controller {
  static values = {
    fileUploaded: String,
  }

  static targets = [
    "qrTypes",
    "closeQrModal",
    "deleteQr",
    "blockQr",
    "modalBlockQr",
    "qrcodeCheckbox",
    "handleLiteCheckbox",
  ];

  qrId = 0;
  selectedQrType = 0;

  connect() {
    const entryAlerts = document.querySelectorAll("#entryAlert");
    if (entryAlerts.length) {
      entryAlerts.forEach((item) => {
        setTimeout(() => item.classList.add("d-none"), 5000);
      });
    }

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    if (params.has("pdfmessage")) {
      const alertError = document.querySelector("#entryAlertError");
      alertError.classList.remove("d-none");
      alertError.querySelector("div").innerText = this.fileUploadedValue;
      setTimeout(() => alertError.classList.add("d-none"), 5000);
      params.delete("pdfmessage");
      url.search = params.toString();
      window.history.replaceState({}, "", url.toString());
    }
    // this.uniqueScans()
  }

  openQrTypesModal(e) {
    this.qrId = e.currentTarget.dataset.id;
    this.selectedQrType = e.currentTarget.dataset.type;
    this.qrTypesTargets.forEach((item) => {
      if (item.dataset.index == e.currentTarget.dataset.type) {
        item.checked = true;
      }
    });
  }

  selectQrType(e) {
    this.selectedQrType = e.currentTarget.dataset.index;
  }

  saveQrType() {
    let locate = document
      .querySelector("[data-locale]")
      .getAttribute("data-locale");
    window.location.href = `/entry/ajax/${this.qrId}/edit-type?type=${this.selectedQrType}&_locale=${locate}`;
    this.closeQrModalTarget.click();
  }

  deleteQr(e) {
    let pagination = document.getElementsByClassName("pagination");
    let totalPages = 1;
    if (pagination.length > 0) {
      totalPages = pagination[0].childElementCount;
    }
    let numberOItems = this.qrcodeCheckboxTargets.length;
    let numberOfSelectedItems = 0;
    this.qrcodeCheckboxTargets.forEach((item) => {
      if (item.checked) {
        formData.append("entries[]", item.dataset.id);
        numberOfSelectedItems++;
      }
    });
    if (totalPages === 1 && numberOItems === numberOfSelectedItems) {
      this.eraseCookie("filterByType");
    }
    this.deleteQrTarget.setAttribute("href", e.currentTarget.dataset.href);
  }

  blockQr(e) {
    e.preventDefault();
    // this.blockQrTarget.dataset.id = e.currentTarget.dataset.id
    // this.blockQrTarget.dataset.block = e.currentTarget.dataset.id

    let target = this.blockQrTarget;
    let block = 1;

    if (target.dataset.block == 1) {
      block = 0;
    }
    window.location.href = `/entry/block/update/${this.blockQrTarget.dataset.id}/${block}`;
  }

  modalBlockQr(e) {
    e.preventDefault();
    this.blockQrTarget.dataset.id = e.currentTarget.dataset.id;
    let block = 0;

    if (e.currentTarget.dataset.block) {
      document.querySelector("#blockQr .modal-title").innerHTML =
        "Unblock QR-Code?";
      document.querySelector("#blockQr .modal-body p").innerHTML =
        "Are you sure you want to unblock QR-Code?";
      let button = document.querySelector("#blockQr .modal-footer a");
      button.innerHTML = "Unblock";
      button.classList.remove("btn-danger");
      button.classList.add("btn-success");
      block = 1;
    }
    this.blockQrTarget.dataset.block = block;
  }

  eraseCookie(name) {
    document.cookie = name + "=; Max-Age=-99999999;";
  }

  showEditField(e) {
    const parent = e.target.closest("h5");
    parent.querySelector(".edit-qr-name").classList.remove("d-none");
    parent.querySelector(".qr-name").classList.add("d-none");
    parent.querySelector(".confirm-icon ").classList.remove("d-none");
    parent.querySelector(".edit-icon").classList.add("d-none");
  }

  async editQrName(e) {
    const parent = e.target.closest("h5");
    const locate = document
      .querySelector("[data-locale]")
      .getAttribute("data-locale");
    const formData = new FormData();
    formData.append("_locale", locate);
    formData.append(
      "title",
      parent.querySelector(".edit-qr-name").value.trim()
    );
    try {
      await axios.post(`/entry/${parent.dataset.id}/edit-title`, formData);
      window.location.reload();
    } catch (e) {
      const alertError = document.querySelector("#entryAlertError");
      alertError.classList.remove("d-none");
      alertError.querySelector("div").innerText = e.response.data.error;
      setTimeout(() => alertError.classList.add("d-none"), 5000);
    }
  }

  async handleLiteCheckbox(e) {
    const entryId = parseInt(e.target.getAttribute('data-entry-id'));
    if (!entryId || isNaN(entryId)) {
      console.error('Entry id must be a number');
      return;
    }
    const isEnable = e.target.checked;

    try {
      const response = await axios.post('/subscription/toggle-lite', {
        entryId: entryId,
        isEnable: isEnable,
      });

      const responseData = response.data.data ?? null;
      const disableEntryLite  = responseData['disableEntry'] ?? null;
      if (disableEntryLite) {
        const qrCheckboxes = document.querySelectorAll(
            `[data-entry-id="${disableEntryLite}"]`
        );

        qrCheckboxes.forEach(qrCheckbox => {
          qrCheckbox.checked = false;
        });
      }
    } catch (error) {
      const response = error.response;
      let message = error.message;
      if (response && response.data && response.data.message) {
        message = response.data.message;
      }

      e.target.checked = false;
      console.error('Error in request to enable/disable lite subscription| ' + message);
    }
  }

  async uniqueScans()
  {
    const formData = new FormData();

    let entryIds = document.querySelectorAll("[data-entry-user-id]")

    entryIds.forEach(function (node) {
      formData.append("entries[]", node.getAttribute("data-id"));
    });
    try {
      const { data } = await axios.post(`/entry/unique-scans`, formData);
      entryIds.forEach(function (node) {
        let entryId = node.getAttribute("data-id");
        node
          .querySelectorAll(".spinner-user-scans")
          .forEach((el) => el.classList.add("d-none"));
        node
          .querySelectorAll(".uniq-users")
          .forEach((el) => (el.innerHTML = data[entryId]));
      });
    } catch (err) {
      console.log(err);
    }
  }
}
