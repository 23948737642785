import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  timeOut = 30 * 1000;
  createQrModal = new Modal(this.createQrModalTarget)

  static targets = ['createQrModal']

  connect() { 
    setTimeout(() => this.createQrModal.show(), this.timeOut);
  }

  closeCreateQrModal() {
    this.createQrModal.hide()
  }
}