import { Controller } from "@hotwired/stimulus";
import axios from "axios";
export default class extends Controller {
  static targets = [
    "alert",
    "tab2",
    "domainName",
    "email",
    "tab4",
    "tab3",
    "script",
    "alertError",
    "sharedTableBody",
    "tableWrapper",
    "emailMove",
    "domainIncorrectErr",
    "userEmailErr",
    "scriptErr",
    "saveBtn",
    "contentLanguage",
  ];

  static values = {
    id: Number,
  };

  async domainSave(e) {
    e.preventDefault();
    const pattern =
      /^(?=^.{4,253}$)(^((?!-)[a-z0-9-]{0,62}[a-z0-9]\.)+[a-z]{2,63}$)/;
    const domainValue = this.domainNameTarget.value;
    if (!pattern.test(domainValue) || !domainValue.length) {
      this.showErrorMsg(this.domainIncorrectErrTarget);
      return;
    }

    let locale = document
      .querySelector("[data-locale]")
      .getAttribute("data-locale");

    if (locale === undefined) {
      locale = "en";
    }

    const formData = new FormData();
    this.domainNameTarget.value &&
    formData.append("domainName", this.domainNameTarget.value);
    formData.append("tab", this.tab3Target.value);
    formData.append("_locale", locale);
    try {
      await axios.post(
        `/entry/settings/${e.currentTarget.dataset.id}/update`,
        formData
      );
      this.showSuccessAlert();
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error) {
        this.showErrorAlert(e.response.data.error);
      } else {
        this.showErrorAlert('An unexpected error occurred.');
      }
    }
  }

  async domainRemove(e) {
    e.preventDefault();
    try {
      await axios(
        `/foreign-domain/${e.target.dataset.id}/entry-domain-delete?entryId=${e.target.dataset.id}&locale=${e.target.dataset.locale}`
      );
      this.domainNameTarget.value = "";
      this.showSuccessAlert();
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error) {
        this.showErrorAlert(e.response.data.error);
      } else {
        this.showErrorAlert('An unexpected error occurred.');
      }
    }
  }

  async move(e) {
    e.preventDefault();
    if (!this.emailMoveTarget.value.trim()) {
      return;
    }
    const form = e.target.closest("form");
    const response = await fetch(form.action, {
      method: "POST",
      body: new FormData(form),
    });
    if (response.url.endsWith("entry")) {
      window.location.href = response.url;
      return;
    }
    const data = await response.json();
    if (data.error) {
      this.showErrorAlert(data.error);
    }
  }

  async share(e) {
    e.preventDefault();
    if (!this.emailTarget.value.trim()) {
      return;
    }
    const formData = new FormData();
    formData.append("email", this.emailTarget.value);
    try {
      let locateElement = document.querySelector("[data-locale]");
      let locate = "en";
      if (locateElement) {
        locate = locateElement.dataset.locale;
      }
      const { data } = await axios.post(
        `/${locate}/entry-share/${this.idValue}`,
        formData
      );
      if (data?.error) {
        this.showErrorAlert(data.error);
        return;
      }
      if (this.tableWrapperTarget.querySelector("table") !== null) {
        this.sharedTableBodyTarget.innerHTML += `
      <tr>
      <td class="align-middle">${this.emailTarget.value}</td>
      <td class="align-middle">
      <span>waiting</span>
      </td>
      </tr>
      `;
      } else {
        this.tableWrapperTarget.innerHTML = `
        <p class="text-dark">Shared for:</p>
        <table class="table">
        <thead>
        <tr>
            <th scope="col">Email</th>
            <th scope="col"></th>
        </tr>
        </thead>
        <tbody class="table-group-divider" data-admin--qrsettings-target="sharedTableBody">
          <tr>
          <td class="align-middle">${this.emailTarget.value}</td>
          <td class="align-middle">
          <span>waiting</span>
          </td>
          </tr>
        </tbody>
        </table>
        `;
      }
      this.emailTarget.value = "";
      this.showSuccessAlert();
    } catch (e) {}
  }

  async saveScript(e) {
    e.preventDefault();
    let scriptLength = this.scriptTarget.value.length;

    if (scriptLength > 0 && (scriptLength > 10000 || scriptLength < 10)) {
      this.showErrorMsg(this.scriptErrTarget);
      return;
    }

    let locale = document
      .querySelector("[data-locale]")
      .getAttribute("data-locale");

    if (locale === undefined) {
      locale = "en";
    }

    const formData = new FormData();
    formData.append("script", this.scriptTarget.value);
    formData.append("tab", this.tab4Target.value);
    formData.append("_locale", locale);
    try {
      await axios.post(`/entry/settings/${this.idValue}/update`, formData);
      this.showSuccessAlert();
    } catch (e) {}
  }

  async removeShare(e) {
    e.preventDefault();
    const id = e.currentTarget.dataset.id;
    const formData = new FormData();
    formData.append("unShare", e.currentTarget.value);
    try {
      let locateElement = document.querySelector("[data-locale]");
      let locate = "en";
      if (locateElement) {
        locate = locateElement.dataset.locale;
      }
      const { data } = await axios.post(
        `/${locate}/entry-share/${id}/modify`,
        formData
      );
      if (data?.error) {
        this.showErrorAlert(data.error);
        return;
      }
      const childElement = this.sharedTableBodyTarget.querySelector(
        `[data-id='${id}']`
      );
      if (childElement) {
        childElement.parentNode.removeChild(childElement);
      }
      this.showSuccessAlert();
    } catch (e) {}
  }

  showErrorAlert(message) {
    this.alertErrorTarget.classList.remove("d-none");
    const alertElement = this.alertErrorTarget.querySelector(`.error`);
    alertElement.innerHTML = message;
    setTimeout(() => {
      this.alertErrorTarget.classList.add("d-none");
      alertElement.innerHTML = "";
    }, 5000);
  }

  showSuccessAlert() {
    this.alertTarget.classList.remove("d-none");
    setTimeout(() => this.alertTarget.classList.add("d-none"), 5000);
  }

  changeDomain(e) {
    const inputValue = e.target.value;
    const trimmedValue = inputValue.replace(/\s/g, "");
    this.domainNameTarget.value = trimmedValue;
    this.saveBtnTarget.setAttribute("disabled", "");
  }

  showErrorMsg(target) {
    target.classList.remove("d-none");
    setTimeout(() => target.classList.add("d-none"), 5000);
  }

  closeAlert(e) {
    e.currentTarget.parentNode.classList.add("d-none");
  }

  async contentLanguageSave(e) {
    e.preventDefault();
    let locale = document
      .querySelector("[data-locale]")
      .getAttribute("data-locale");

    if (locale === undefined) {
      locale = "en";
    }

    const formData = new FormData();
    formData.append("local", this.contentLanguageTarget.value);
    formData.append("tab", "local");
    formData.append("_locale", locale);
    try {
      const { data } = await axios.post(
        `/entry/settings/${e.target.dataset.id}/update`,
        formData
      );
      if (data?.error) {
        this.showErrorAlert(data.error);
        return;
      }
      this.showSuccessAlert();
    } catch (e) {}
  }
}
