import { Controller } from "@hotwired/stimulus";
import { CurrencyService } from "../../service/currency/currency_service";
import { PricePlanService } from "../../service/payment/price_plan_service";
import { ProductPriceService } from "../../service/payment/product-price/product_price_service";

export default class extends Controller {
  constructor(context) {
    super(context);

    this._currencyService = new CurrencyService();
    this._pricePlanService = new PricePlanService();
    this._productPriceService = new ProductPriceService();
  }

  connect() {
    this._currencyService.loadCurrenciesData();
    this._pricePlanService.loadPriceData();
  }

  changeCurrencyHandler(e)
  {
    const selectedCurrency = e.target.value;
    const currency = this._currencyService.getCurrencyByCode(selectedCurrency);
    if (!currency) {
      console.error(`Selected currency '${selectedCurrency}' is not valid`);
      return;
    }
    const premiumYearPrice = this._pricePlanService.getPremiumYearPriceByCurrency(selectedCurrency);
    const premiumMonthPrice = this._pricePlanService.getPremiumMonthPriceByCurrency(selectedCurrency);
    const liteYearPrice = this._pricePlanService.getLiteYearPriceByCurrency(selectedCurrency);
    const liteMonthPrice = this._pricePlanService.getLiteMonthPriceByCurrency(selectedCurrency);
    if (!premiumYearPrice || !premiumMonthPrice || !liteYearPrice || !liteMonthPrice) {
      console.error(`Failed to getting prices for '${currency.code}' currency`);
      return;
    }

    this._productPriceService.replaceCurrencyForPurchaseButton(currency.code);
    this._productPriceService.replacePricingOnPage(
        premiumYearPrice,
        premiumMonthPrice,
        liteYearPrice,
        liteMonthPrice,
        currency,
    );
  }
}
