export const validEmail = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
export const validTelNumber = new RegExp(/^[0-9]{8,20}$/);
export const validOnlyNumber = new RegExp(/^[0-9]+$/);
export const validOnlyLetter = new RegExp(/^[A-Za-z]{3,250}$/);
export const validName = new RegExp(/^[a-zA-Z '.-]{3,100}$/);
export const validOnlyLetterAndNumber = new RegExp(/^[0-9a-zA-Z]+$/);
export const validUrl = RegExp(
  "^(?:(?:http|https)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$"
);
export const whatsappValidNumber = RegExp(
  /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/
);
export const whatsappValidNumberDontHaveLetter = RegExp(/^[0-9 +]{8,20}$/);
export const validLinkPattern = RegExp(
  /^(.+:\/\/)?(?:www\.)?.+(\..{1,}|:\d{2,5}|\/.+)/
);
export const validGoogleMap = RegExp(
  /@([-+]?[0-9\.]+),([-+]?[0-9\.]+),([0-9z]+)/
);
export const validShortGoogleMap = RegExp(
  /^https?:\/\/goo\.gl\/maps\/[a-zA-Z0-9]{17}$/
);
export const validShortGoogleMap2 = RegExp(
  /^https?:\/\/maps\.app\.goo\.gl\/.*$/
);
export const validEmailList = new RegExp(
  /^\s*[\w.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*(,\s*[\w.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*)*$/
);
export const validLinkProtocol = RegExp(/^[a-zA-Z]+:\/\/[a-zA-Z0-9-]{2,}/);
