import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  validEmail = /^(\w{2,100})+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
  static targets = ["input", "form", "checkbox"];

  async addEmail(e) {
    e.preventDefault();

    if (!this.validEmail.test(this.inputTarget.value)) {
      this.alertMessage("Incorrect email address", "alert-danger");
      return;
    }

    const formData = new FormData(this.formTarget);
    try {
      const { data } = await axios.post(e.params.url, formData);
      this.inputTarget.value = "";
      if (data?.mail_send === true) {
        this.alertMessage("Email created", "alert-success");
        window.location.reload();
      }
      if (data?.subscribed === false) {
        this.alertMessage("Email already added", "alert-danger");
      }
    } catch (error) {
      this.alertMessage(error.response.data?.title, "alert-danger");
    }
  }

  alertMessage(message, className) {
    const alertDiv = document.createElement("div");
    alertDiv.innerHTML = `<div class="alert ${className}">${message}</div>`;
    this.element.prepend(alertDiv);
    setTimeout(() => alertDiv.remove(), 5000);
  }

  async setMailingSubscribe(e) {
    const value = e.currentTarget.value;
    const checked = e.currentTarget.checked;
    e.preventDefault();
    try {
      await axios(`/mailing/edit/${value}?active=${checked ? 1 : 0}`);
      this.checkboxTargets.find((item) => item.value == value).checked =
        checked;
    } catch (error) {
      console.log(error);
    }
  }
}
