import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    this.automaticSubmit();
    this.successSubmit();
  }

  automaticSubmit() {
    let form = document.getElementById('2faForm');
    let submitBtn = document.getElementById('submitBtn');
    if (form && submitBtn) {
      submitBtn.addEventListener('click', () => {
        form.submit();
      });
    }
  }

  successSubmit() {
    let authenticatorSwitchOn = document.getElementById('switchOn');
    let authenticatorOn = document.getElementById('authenticatorOn');
    let authenticatorSwitchOff = document.getElementById('switchOff');
    let authenticatorOff = document.getElementById('authenticatorOff');
    let form = document.getElementById("2faForm");
    let error = document.getElementById('auth_code_error')
    let formError = document.getElementById('_auth_code')

    if (form) {
      form.addEventListener("submit", (event) => {
        event.preventDefault();
        let formData = new FormData(form);
        let xhr = new XMLHttpRequest();
        let actionUrl = form.getAttribute("action");
        xhr.open(form.getAttribute("method"), actionUrl);
        let locale = document.querySelector('[data-locale]').getAttribute('data-locale');
        formData.append('locale', locale);

        xhr.onload = () => {
          if (xhr.status === 200) {
            this.closeModal();
            if (actionUrl.includes("/2fa_enable")) {
              authenticatorOff.classList.add("d-none");
              authenticatorSwitchOn.classList.remove("d-none");
              this.showSuccessAlert(true);
            } else if (actionUrl.includes("/2fa_disable")) {
              authenticatorOn.classList.add("d-none");
              authenticatorSwitchOff.classList.remove("d-none");
              this.showSuccessAlert(false);
            }
          } else if (xhr.status === 400) {
            error.classList.remove("d-none");
            formError.classList.add("is-invalid");
            setTimeout(() => {
              error.classList.add("d-none");
              formError.classList.remove("is-invalid");
            }, 3000);
          }


        };

        xhr.send(formData);
      });
    }
  }

  closeModal() {
    let modalElement = document.getElementById('googleAuthenticatorModal');
    if (modalElement) {
      let modal = bootstrap.Modal.getInstance(modalElement);
      if (modal) {
        modal.hide();
      }
    }
  }

  showSuccessAlert(isOn) {
    let alert = document.getElementById('googleAlert');
    let alertOn = document.getElementById('googleActivatedAlert');
    let alertOff = document.getElementById('googleDeactivatedAlert');

      if (isOn) {
        alertOn.classList.remove("d-none");
        alertOff.classList.add("d-none");
        alert.classList.remove("d-none");

      } else {
        alertOff.classList.remove("d-none");
        alertOn.classList.add("d-none");
        alert.classList.remove("d-none");


      setTimeout(() => alert.classList.add("d-none"), 5000);
    }
  }
}
