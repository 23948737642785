import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    connect() {
      document.addEventListener("DOMContentLoaded", function() {
        let errorDiv = document.querySelector("[data-seconds]");
        if (errorDiv) {
          let secondsBlock = errorDiv.querySelector("span");
          let secondsInt = errorDiv.getAttribute("data-seconds");
          let buttonLogin = document.querySelector(".register-card-left-btn");
          buttonLogin.disabled = true
          buttonLogin.classList.remove('text-white')
          let updateTime = setInterval(() => {
            secondsInt = secondsInt - 1;
            if (secondsInt < 1) {
              errorDiv.classList.add("d-none");
              buttonLogin.disabled = false;
              buttonLogin.classList.add('text-white')
            }
            secondsBlock.innerHTML = secondsInt;
          }, 1000);

          setTimeout(() => {
            clearInterval(updateTime);
          }, 60000);
        }

      }, false);
    }
}