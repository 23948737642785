import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = [
    "saveBtn",
    "select",
    "infoUploadDiv",
    "infoUploadButton",
    "fileName",
    "fileSize",
    "file",
    "uploadDiv",
    "sendBtn",
    "downloadCsvBtn",
  ];

  connect() {
    let userDomain = document.querySelector("#user_domain_domainName");
    if (userDomain) {
      userDomain.addEventListener("input", this.changeDomain.bind(this));
    }
  }

  async downloadCsv(e) {
    const selectType = this.selectTarget;
    const selectedFile = selectType.value;
    if (!selectedFile) {
      return false;
    }
    const imgElements = e.target.querySelectorAll("svg");
    imgElements[0].classList.remove("d-none");
    imgElements[1].classList.add("d-none");
console.log(selectedFile);
    const { data } = await axios(selectedFile);
    const blob = new Blob([data], { type: "text/csv" });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = "example.csv";
    document.body.appendChild(a);
    a.click();
  }

  changeDomain(e) {
    const inputValue = e.target.value;
    const trimmedValue = inputValue.replace(/\s/g, "");
    e.target.value = trimmedValue;
    this.saveBtnTarget.setAttribute("disabled", "");
    this.saveBtnTarget.classList.add("text-white", "btn-light-gray");
  }
  messageInput(e) {
    let maxFileSize = 1024 * 1024 * 10;
    const file = this.fileTarget.files[0] ?? "undefined";

    if (file !== "undefined") {
      let kb = file.size / 1024;
      this.fileNameTarget.innerHTML = file.name;
      this.fileSizeTarget.innerHTML = kb.toFixed(2) + " KB";
      this.uploadDivTarget.classList.add("d-none");
      this.infoUploadDivTarget.classList.remove("d-none");
      if (file.size > maxFileSize) {
        this.maxFileSizeTarget.classList.add("text-danger");
        this.maxFileSizeTarget.classList.remove("text-gray");
      }
    }
    if (file !== "undefined" && file.size < maxFileSize) {
      this.sendBtnTarget.disabled = false;
      this.sendBtnTarget.classList.add("btn-primary");
      this.sendBtnTarget.classList.remove("text-white", "bg-light-gray");
    }
  }

  async deleteFile(e) {
    e.preventDefault();
    this.fileNameTarget.innerHTML = "";
    this.fileSizeTarget.innerHTML = "";
    this.uploadDivTarget.classList.remove("d-none");
    this.infoUploadDivTarget.classList.add("d-none");
    this.fileTarget.value = "";
    this.sendBtnTarget.disabled = true;
    this.sendBtnTarget.classList.remove("btn-primary");
    this.sendBtnTarget.classList.add("text-white", "bg-light-gray");
  }

  selectExampleCSV(e) {
    const imgElements = this.downloadCsvBtnTarget.querySelectorAll("svg");
    imgElements[1].classList.remove("d-none");
    imgElements[0].classList.add("d-none");
  }

  async generateCodes(e) {
    e.preventDefault();
    let url = e.target.action;
    let target = e.target;
    let template = target.querySelectorAll("#entry_csv_add_form_template");
    template.forEach(function (currentValue) {
      if (currentValue.value) {
        url += "?template=" + currentValue.value;
      }
    });

    const formValues = new FormData(e.target);
    const preloader = document.querySelector(".loaderWrapper");
    preloader.classList.remove("d-none");
    try {
      await axios.post(url, formValues);
      window.location.reload();
    } catch (e) {
      preloader.classList.add("d-none");
    }
  }
}
