import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import debounce from "debounce";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "qrcode",
    "qrEntry",
    "emailError",
    "changeOwnerBtn",
    "deleteQrBtn",
    "email",
    "cancel",
  ];

  changeOwnerModal = new Modal("#changeOwnerModal");

  async connect() {
    import("qr-code-styler").then((QRCodeStyling) => {
      this.qrcodeTargets.forEach((item) => {
        let newQr = new QRCodeStyling.default({ data: item.dataset.link });
        newQr.append(item);
      });
    });
    this.emailInput = await debounce(this.emailInput.bind(this), 500);
  }

  deleteQr(e) {
    e.currentTarget.parentNode.parentNode.remove();
    if (!this.qrEntryTargets.length) {
      this.changeOwnerBtnTarget.setAttribute("disabled", "");
    }
    if (this.qrEntryTargets.length === 1) {
      this.deleteQrBtnTarget.classList.add("d-none");
    }
  }

  async emailInput(e) {
    const formData = new FormData();
    formData.append("email", e.target.value);
    try {
      const { data } = await axios.post("/entry/email_exists", formData);
      if (data.error) {
        this.showErrorMessage(this.emailErrorTarget);
        this.changeOwnerBtnTarget.setAttribute("disabled", "");
      } else {
        this.emailErrorTarget.classList.add("d-none");
        if (this.qrEntryTargets.length) {
          this.changeOwnerBtnTarget.removeAttribute("disabled");
        }
      }
    } catch (e) {
      this.showErrorMessage(this.emailErrorTarget);
      this.changeOwnerBtnTarget.setAttribute("disabled", "");
    }
  }

  showErrorMessage(alertItem) {
    alertItem.classList.remove("d-none");
  }

  changeOwnerModalOpen() {
    document.querySelector("#moveToEmail").innerHTML = this.emailTarget.value;
    document.querySelector("#moveCount").innerHTML = this.qrEntryTargets.length;
  }

  async moveQrCodes() {
    const formData = new FormData();
    this.qrEntryTargets.forEach((item) => {
      formData.append("entries[]", item.dataset.id);
    });
    formData.append("email", this.emailTarget.value);
    try {
      let locate = document.querySelector('[data-locale]').getAttribute('data-locale')
      formData.append('_locale', locate)
      await axios.post("/entry/change_owner_request", formData);
      this.changeOwnerModal.hide();
      this.cancelTarget.click();
    } catch (e) {
      const responseData = e.response.data;
      const errorBlock = document.getElementById('change_owner_error_box');
      if (errorBlock && responseData.error) {
        errorBlock.textContent = responseData.error;
        errorBlock.removeAttribute('hidden');
      }
    }
  }
}
