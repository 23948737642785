import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["fileName", "fileSize", "selectBlock",
        "showBlock", "artForm", "captchaError", "selectBlockInput", "selectBlockButton", "isHaveLogo"];
connect() {
    this.changeState()
    this.formLogoError()
}
    handleFileSelect(event) {
        const file = event.target.files[0];

        if (file) {
            this.fileNameTarget.innerHTML = "Name: " + file.name;
            this.fileSizeTarget.innerHTML = "Size: " + this.formatFileSize(file.size);
            this.selectBlockTarget.style.display = "none";
            this.showBlockTarget.style.display = "block";
        } else {
            this.fileNameTarget.innerHTML = "";
            this.fileSizeTarget.innerHTML = "";
            this.selectBlockTarget.style.display = "block";
            this.showBlockTarget.style.display = "none";
        }
    }

    formatFileSize(sizeInBytes) {
        const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
        const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        return (sizeInBytes / Math.pow(1024, i)).toFixed(2) * 1 + " " + sizes[i];
    }

    deleteFile() {
        this.fileNameTarget.innerHTML = "";
        this.fileSizeTarget.innerHTML = "";
        this.selectBlockTarget.style.display = "block";
        this.showBlockTarget.style.display = "none";
    }

    validateCaptcha(event) {
        event.preventDefault();

        grecaptcha.ready(() => {
            let captchaResponse = grecaptcha.getResponse();
            if (captchaResponse.length === 0) {
                this.captchaErrorTarget.style.display = "block";

                this.hideAfterDelay(this.captchaErrorTarget);
            } else {
                this.captchaErrorTarget.style.display = "none";
                this.artFormTarget.submit();
            }
        });
    }
    setDefaultState() {
        this.selectBlockInputTarget.disabled = true;
        this.selectBlockButtonTarget.classList.add('opacity-25');
        this.selectBlockButtonTarget.style.cursor = "default";
    }
    changeState(){
        document.addEventListener('DOMContentLoaded', () => {
            const form = this.isHaveLogoTarget
            if (!!form) {
                form.addEventListener("change", e => {
                    const formTarget = e.target.value;
                    if (formTarget !== "Yes"){
                        this.setDefaultState()
                    }
                    else {
                        this.selectBlockInputTarget.disabled = false
                        this.selectBlockButtonTarget.classList.remove('opacity-25')
                        this.selectBlockButtonTarget.style.cursor = "pointer"

                    }
                });
            }
        });
    }
    formLogoError() {
        let error = document.querySelector(".logo-error");
        let errorField = document.getElementById("art_qr_code_isHaveLogo");
        if (error.querySelector('ul')) {
            errorField.style.border = '1px solid #f44336';
                   }
        else {
            errorField.style.border = 'none';
        }
    }

}