import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static values = {
    newHome: Boolean,
  };
  static targets = [
    "liteYear",
    "premiumYear",
    "saveLiteYear",
    "lite",
    "premium",
    "saveLite",
    "savePremium",
    "free",
    "monthlyPlan",
    "annualPlan",
    "getPremiumYearBtn",
    "getPremiumMonthBtn",
    "getLiteYearBtn",
    "getLiteMonthBtn",
  ];

  symbol = {
    usd: "$",
    aud: "A$",
    brl: "R$",
    eur: "€",
    gbp: "£",
    idr: "Rp",
    inr: "₹",
    sar: "SR",
    thb: "฿",
    uah: "₴",
    vnd: "₫",
  };

  liteYearPrice = 0;
  premiumPrice = 0;
  plansData = {};

  connect() {
    axios("/get-all-plans").then(({ data }) => {
      this.plansData = data;
      this.setData("usd");
    });
  }

  setData(currency) {
    this.setPrice(
      this.liteYearTargets,
      this.plansData["lite_year"],
      currency,
      true
    );
    this.setPrice(
      this.premiumYearTargets,
      this.plansData["premium_year"],
      currency,
      true
    );
    this.setPrice(
      this.liteTargets,
      this.plansData["lite_month"],
      currency,
      false
    );
    this.setPrice(
      this.premiumTargets,
      this.plansData["premium_month"],
      currency,
      false
    );
    this.calculateSavePrice(currency);
    this.freeTargets.forEach(
      (target) => (target.innerHTML = `${this.symbol[currency]}0`)
    );
    this.setCheckoutLinks(currency);
  }

  setPrice(targets, data, currency, isYear) {
    const price = data.find((item) => item.currency === currency).amount;
    targets.forEach((target) => {
      const amount = isYear ? (price / 12).toFixed(2) : price;
      target.innerHTML = `${this.symbol[currency]}${amount}`;
    });
  }

  calculateSavePrice(currency) {
    const liteAmountYear = this.plansData["lite_year"].find(
      (item) => item.currency === currency
    ).amount;
    const liteAmountMonth = this.plansData["lite_month"].find(
      (item) => item.currency === currency
    ).amount;
    const amountLite = (liteAmountMonth * 12 - liteAmountYear).toFixed();
    this.saveLiteTargets.forEach((target) => {
      target.innerHTML = `${this.symbol[currency]}${amountLite}`;
    });

    const premiumAmountYear = this.plansData["premium_year"].find(
      (item) => item.currency === currency
    ).amount;
    const premiumAmountMonth = this.plansData["premium_month"].find(
      (item) => item.currency === currency
    ).amount;
    const amountPremium = (
      premiumAmountMonth * 12 -
      premiumAmountYear
    ).toFixed();
    this.savePremiumTargets.forEach((target) => {
      target.innerHTML = `${this.symbol[currency]}${amountPremium}`;
    });
  }

  toggle(event) {
    this.setData(event.target.value);
  }

  selectMonthPlan() {
    this.monthlyPlanTargets.forEach((item) => {
      item.classList.add("d-none");
    });
    this.annualPlanTargets.forEach((item) => {
      item.classList.remove("d-none");
    });
  }

  selectAnnualPlan() {
    this.monthlyPlanTargets.forEach((item) => {
      item.classList.remove("d-none");
    });
    this.annualPlanTargets.forEach((item) => {
      item.classList.add("d-none");
    });
  }

  setCheckoutLinks(currency) {
    this.getPremiumYearBtnTargets.forEach((item) => {
      item.href = `/subscription/payment-middleware?currency=${currency}&type=premium_year`;
    });
    this.getPremiumMonthBtnTargets.forEach((item) => {
      item.href = `/subscription/payment-middleware?currency=${currency}&type=premium_month`;
    });
    this.getLiteYearBtnTargets.forEach((item) => {
      item.href = `/subscription/lite-middleware/null?currency=${currency}&type=lite_year`;
    });
    this.getLiteMonthBtnTargets.forEach((item) => {
      item.href = `/subscription/lite-middleware/null?currency=${currency}&type=lite_month`;
    });
  }

  getPlanBtn(e) {
    dataLayer.push({
      event: `${this.newHomeValue ? "new-" : ""}button_get_${e.params.label}`,
    });
  }

  multiSubscribeNavigate(e) {
    const locale = document.querySelector('[data-locale]').getAttribute('data-locale')
    document.cookie = `multiSub=1; path=${locale === 'en' ? "" : `/${locale}`}/subscription`;
    window.location.href = e.target.dataset.href;
  }
}
