import { Controller } from '@hotwired/stimulus';
export default {
  'symfony--ux-react--react': new Promise((resolve, reject) => resolve({ default:
      (function() {
          return class LazyController extends Controller {
              constructor(context) {
                  super(context);
                  this.__stimulusLazyController = true;
              }
              initialize() {
                  if (this.application.controllers.find((controller) => {
                      return controller.identifier === this.identifier && controller.__stimulusLazyController;
                  })) {
                      return;
                  }
                  import('@symfony/ux-react/dist/render_controller.js').then((controller) => {
                      this.application.register(this.identifier, controller.default);
                  });
              }
          }
      })()
  })),
};