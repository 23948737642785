import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["output"];
  static values = {
    url: String,
    timeout: Number,
  }

  connect() {
    // if (this.timeoutValue) {
    //   setTimeout(this.request(), this.t)
    // } else {
      this.request();
    // }
  }

  async request() {
    const response = await fetch(this.urlValue);
    this.outputTarget.innerHTML =  await response.text();
  }
}
