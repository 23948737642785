import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import account_access_pagination_controller from "./account_access_pagination_controller";

export default class extends Controller {
  static targets = ["modal", "removeModal", "options", "accessType",
    "alert", "tab2", "owner", "sharedUser", "hideAccessType", "confirmButton",
    "removeButton", "userRow", "table", "pagination"];
  static controllers = [account_access_pagination_controller];

  openModal(e) {
    const clickedElement = e.currentTarget;
    this.currentRow = clickedElement.closest("tr");
    const sharedUserElement = this.currentRow.querySelector(".sharedUser");
    const sharedUser = sharedUserElement.textContent;
    const sharedUserSpan = this.modalTarget.querySelector("span[data-shared-user]");
    sharedUserSpan.textContent = sharedUser;

    this.modalTarget.style.display = "block";
  }

  openRemoveModal(e) {
    e.preventDefault();

    const clickedElement = e.currentTarget;
    this.currentRow = clickedElement.closest("tr");
    const sharedUserElement = this.currentRow.querySelector(".sharedUser");
    const sharedUser = sharedUserElement.textContent;
    const sharedUserSpan = this.removeModalTarget.querySelector("span[data-shared-user]");
    sharedUserSpan.textContent = sharedUser;

    this.removeButtonTarget.dataset.recordId = e.target.dataset.recordId;

    this.removeModalTarget.style.display = "block";
  }

  close() {
    this.modalTarget.style.display = "none";
  }

  closeRemoveModal() {
    this.removeModalTarget.style.display = "none";
  }

  async save(e) {
    e.preventDefault();

    const sharedUserSpan = this.modalTarget.querySelector("span[data-shared-user]");
    const sharedUser = sharedUserSpan.textContent;

    const formData = new FormData();
    formData.append("sharedEmail", sharedUser);
    formData.append("accessType", this.accessTypeTarget.value);
    formData.append("tab", this.tab2Target.value);

    try {
      const response = await axios.post("/account/update", formData);
      this.updateAccessTypeInTable(response.data.accessType);
    } catch (e) {
      console.error(e);
    }

    this.modalTarget.style.display = "none";
  }

  updateAccessTypeInTable(accessType) {
    const accessTypeCell = this.currentRow.querySelector(".hideAccessType");
    accessTypeCell.innerHTML = "";
    let accessTypeBox, accessTypeText;

    if (accessType == 1) {
      accessTypeBox = document.createElement("div");
      accessTypeBox.className = "editorAccessTypeBox";
      accessTypeText = document.createElement("span");
      accessTypeText.className = "editorAccessTypeText";
      accessTypeText.textContent = "Editor";
    } else {
      accessTypeBox = document.createElement("div");
      accessTypeBox.className = "adminAccessTypeBox";
      accessTypeText = document.createElement("span");
      accessTypeText.className = "adminAccessTypeText";
      accessTypeText.textContent = "Admin";
    }

    accessTypeBox.appendChild(accessTypeText);
    accessTypeCell.appendChild(accessTypeBox);
  }

  async removeRecord(e) {
    e.preventDefault();
    const recordId = this.removeButtonTarget.dataset.recordId;
    try {
      axios.post(
        "/account/access/remove/" + recordId,
        { recordId }
      ).then(response => {
        if (response.data.success) {
          const row = Array.from(this.userRowTargets).find(row => row.dataset.recordId === recordId);
          if (row) {
            row.remove();

            const currentPageLink = this.paginationTarget.querySelector(".active a");
            if (currentPageLink) {
              let page1 = currentPageLink.href = "/account?page=1";
              if (currentPageLink.href !== page1) {
                currentPageLink.click();
              }
            }
          }
        }
      });
    } catch (e) {
      console.error(e);
    }

    this.removeModalTarget.style.display = "none";
  }
}
