import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  showModal(e) {
    const modal = document.createElement("div");
    modal.innerHTML = `<div class="modal fade" id="youtubeModal" tabindex="-1" aria-labelledby="youtubeModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div class="ratio ratio-16x9">
                <iframe width="560" height="315" src="${e.target.dataset.link}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
  </div>`;

    document.body.appendChild(modal);
    const youtubeModal = new Modal(modal.querySelector("#youtubeModal"));
    youtubeModal.show();

    modal.addEventListener("hidden.bs.modal", () => {
      const iframe = modal.querySelector("iframe");
      iframe.src = "";
    });
  }
}
