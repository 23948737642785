import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["iframe"];
  static values = {
    lang: String,
    type: String,
  };

  language = this.langValue;

  connect() {
    if (this.language === "en") {
      this.language = "";
    } else {
      this.language = this.langValue + "/";
    }
    setTimeout(
      () =>
        this.iframeTarget.setAttribute(
          "src",
          `${window.location.origin}/${this.language}qr-code-generator/${this.typeValue}/iframe`
        ),
      4000
    );
  }
}
