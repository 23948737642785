import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import { Modal } from "bootstrap";
export default class extends Controller {
  static targets = [
    "closeModal",
    "input",
    "inputEmptyErr",
    "inputDublicateErr",
    "inputWrongErr",
  ];

  customUrlModal = new Modal("#customUrlModal");

  connect() {
    customUrlModal.addEventListener("hide.bs.modal", () => {
      document.querySelector("#customUrlDelete").classList.remove("d-none");
    });
  }

  init(e) {
    document.querySelector("#customUrlInput").value = e.detail.link;
    document.querySelector("#customUrlDelete").href = e.detail.deleteUrl;
    document.querySelector("#customUrlUpdate").dataset.href =
      e.detail.updateUrl;
    e.detail.hideDelete &&
      document
        .querySelector("#customUrlDelete")
        .classList.add(e.detail.hideDelete);
  }

  async update(e) {
    const urlPattern = /^[0-9a-zA-Z\-\/]+$/;
    if (!this.inputTarget.value.length) {
      this.showErrorMsg(this.inputEmptyErrTarget);
      return;
    }
    if (!urlPattern.test(this.inputTarget.value)) {
      this.showErrorMsg(this.inputWrongErrTarget);
      return;
    }
    const formData = new FormData();
    formData.append("url", this.inputTarget.value);
    try {
      const { data } = await axios.post(e.currentTarget.dataset.href, formData);
      if (data.error) {
        this.showErrorMsg(this.inputDublicateErrTarget);
        return;
      }
      this.closeModalTarget.click();
      window.location.reload();
    } catch (e) {}
  }

  showErrorMsg(target) {
    target.classList.remove("d-none");
    setTimeout(() => target.classList.add("d-none"), 5000);
  }
}
