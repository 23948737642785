import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
    static targets = ["beforeVote", "afterVote", "showRating", "ratingGroup", "averageRating", "noRatingDescription", "showRatingPercent"];

    connect() {
        this.selectedRating = 0;
        this.checkVoteStatus();
    }

    checkVoteStatus() {
        const hasVoted = this.getCookie('hasVoted');
        if (hasVoted === 'true') {
            this.showAfterVoteElements();
        } else {
            this.showBeforeVoteElements();
        }
    }

    showAfterVoteElements() {
        this.ratingGroupTarget.classList.add("d-none");
        this.averageRatingTarget.classList.remove("d-none");
        this.noRatingDescriptionTarget.classList.add("d-none");
        this.showRatingTarget.classList.remove("d-none");
        this.afterVoteTarget.classList.remove("d-none");
        this.beforeVoteTarget.classList.add("d-none");
    }

    showBeforeVoteElements() {
        this.ratingGroupTarget.classList.remove("d-none");
        this.averageRatingTarget.classList.add("d-none");
        this.noRatingDescriptionTarget.classList.remove("d-none");
        this.showRatingTarget.classList.add("d-none");
        this.afterVoteTarget.classList.add("d-none");
        this.beforeVoteTarget.classList.remove("d-none");
    }

    onRatingChange(event) {
        if (event.target.name === 'fst') {
            this.selectedRating = event.target.value;

            // this.submitButtonTarget.classList.remove("d-none");
            this.submitVote()
        }
    }

    submitVote() {
        this.setCookie('hasVoted', 'true', 365);
        // this.submitButtonTarget.classList.add("d-none");
        this.showAfterVoteElements();

        const pageId = this.ratingGroupTarget.getAttribute('data-page-id');
        const ratingText = this.averageRatingTarget;
        const ratingPercent = this.showRatingPercentTarget;

        axios.post(`/rating/static-page/${pageId}`, {
            rating: this.selectedRating
        })
            .then(function(response) {
                ratingText.textContent = `${
                  ratingText.dataset.average
                } ${JSON.stringify(response.data.averageRating)}/5 ${
                  ratingText.dataset.votes
                } ${JSON.stringify(response.data.totalVotes)}`;
                ratingPercent.style.left = (response.data.averageRating * 2 * 10) + '%';

            })
            .catch(function(error) {
                console.error('An error occurred');
            });
    }


    setCookie(name, value, days) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        const path = window.location.pathname;
        document.cookie = `${name}=${value || ""}${expires}; path=${path}; SameSite=Lax`;
    }

    getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
}
