import axios from "axios";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["qrCount"];

  connect() {
    this.qrCountTargets.forEach((target) => {
      target.style.cssText += "opacity: 0";
    });

    axios.get("/qr-count").then(({ data }) => {
      this.qrCountTargets.forEach((target) => {
        target.style.cssText += "opacity: 1";
        target.innerHTML = data;
      });
    });
  }
}
