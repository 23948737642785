import axios from "axios";
import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "okBtn",
    "selectFolder",
    "closeModal",
    "input",
    "inputCreateErr",
    "selectFolderErr",
  ];

  moveToFolderModal = new Modal("#moveToFolderModal");
  moveFromPremiumToRegularFolderModal = new Modal("#moveFromPremiumToRegularFolderModal");

  init(e) {
    document
      .querySelectorAll("#qrId")
      .forEach((item) => {
        item.dataset.qr = e.detail.qrId
        item.setAttribute('data-is-premium', e.detail.isPremium)
        item.setAttribute('data-folder-name', e.detail.folderName)
      });
  }

  move(e) {

    let target = e.target;
    let titleElement = document.querySelector(".title_from_premium_to_regular_folder");
    let locale = this.getCookie("user_local");
    if (locale === undefined) {
      locale = "en";
    }
    if (target.classList.contains("premiumToRegularFolderConfirm") === false) {
      let options = this.selectFolderTarget.options;
      let selectedIndex = this.selectFolderTarget.selectedIndex;
      let premiumFolderTo = options[selectedIndex].getAttribute("data-premium-folder");
      let selectedFolderName = target.getAttribute('data-folder-name')
      let premiumFolderFrom = target.getAttribute('data-is-premium')

      if (premiumFolderFrom === "1" && premiumFolderTo === "") {
        this.moveToFolderModal.hide();
        this.moveFromPremiumToRegularFolderModal.show();

        let title = titleElement.innerHTML;
        let titleSplip = title.split("/");

        let newTitle = titleSplip[0] + selectedFolderName + titleSplip[1];
        if (["ar", "he"].includes(locale)) {
          newTitle = titleSplip[1] + selectedFolderName + titleSplip[0];
        }
        titleElement.innerHTML = newTitle;
        titleElement.setAttribute("data-folder-id", this.selectFolderTarget.value);
        titleElement.setAttribute("data-qr-id", e.currentTarget.dataset.qr);
        return false;
      } else {
        this.moveFromPremiumToRegularFolderModal.hide();
      }
    }

    let folderId = titleElement.getAttribute("data-folder-id");

    if (folderId > 0) {
      try {
        const formData = new FormData();
        formData.append("folderId", folderId);
        formData.append("_locale", locale);
        axios
          .post(`/entry/folder/${titleElement.getAttribute("data-qr-id")}`, formData)
          .then((resp) => {
            let data = resp.data;
            if (data.status === "error") {
              this.selectFolderErrTarget.classList.remove("d-none");
              this.selectFolderErrTarget.innerHTML = data.error;
              setTimeout(
                () => window.location.reload(),
                4000
              );
            }else {
              window.location.reload()
            }
          });
      } catch (err) {
      }
      return true;
    }

    if (this.selectFolderTarget.value === "select") {
      this.selectFolderErrTarget.classList.remove("d-none");
      setTimeout(
        () => this.selectFolderErrTarget.classList.add("d-none"),
        5000
      );
      return;
    }
    try {
      const formData = new FormData();
      formData.append("folderId", this.selectFolderTarget.value);
      axios
        .post(`/entry/folder/${e.currentTarget.dataset.qr}`, formData)
        .then(() => {
          this.closeModalTarget.click();
          window.location.reload();
        });
    } catch (err) {}
  }

  async createFolder() {
    if (!this.inputTarget.value.trim().length) {
      this.inputCreateErrTarget.classList.remove("d-none");
      setTimeout(() => this.inputCreateErrTarget.classList.add("d-none"), 5000);
      return;
    }
    const formData = new FormData();
    formData.append("name", this.inputTarget.value);
    try {
      const { data } = await axios.post("/folder/new", formData);
      const dataForm = new FormData();
      dataForm.append("folderId", data.id);
      let qrIds = this.okBtnTarget.dataset.qr;
      if (qrIds.includes("]")) {
        qrIds = JSON.parse(qrIds);
        qrIds.forEach((item) => {
          dataForm.append("entries[]", item);
        });
        await axios.post("/entry/bulk/folder", dataForm);
      } else {
        await axios.post(
          `/entry/folder/${qrIds}`,
          dataForm
        );
      }
      this.closeModalTarget.click();
      window.location.reload();
    } catch (err) {
    }
  }

  getCookie(cookieName) {
    let cookie = {};
    document.cookie.split(";").forEach(function (el) {
      let [key, value] = el.split("=");
      cookie[key.trim()] = value;
    });
    return cookie[cookieName];
  }
}
