export class ProductPriceEnum
{
    static purchaseBtnClass = 'purchase-btn';
    static premiumYearPriceTextClass = 'premium-year-price';
    static premiumMonthPriceTextClass = 'premium-month-price';
    static liteYearPriceTextClass = 'lite-year-price';
    static liteMonthPriceTextClass = 'lite-month-price';
    static premiumYearPerMonthPriceTextClass = 'premium-year-price-per-month';
    static liteYearPerMonthPriceTextClass = 'lite-year-price-per-month';
    static premiumAmountSavingTextClass = 'premium-amount-saving';
    static liteAmountSavingTextClass = 'lite-amount-saving';
}