import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import { validEmail } from "../../../RegExpValidations";

export default class extends Controller {
  static targets = [
    "refLink",
    "id",
    "amount",
    "method",
    "login",
    "date",
    "status",
    "withdrawRow",
    "payoneerMethod",
    "swiftMethod",
    "country",
    "city",
    "phone",
    "amountValue",
    "withdrawBtn",
    "email",
    "payoneerRecipientAddress",
    "payoneerRecipientIndex",
    "payoneerAccountType",
    "payoneerBankName",
    "payoneerIban",
    "payoneerName",
    "payoneerCard",
    "swiftRecipientName",
    "swiftRecipientAddress",
    "swiftBankName",
    "swiftBankAddress",
    "swiftIban",
    "swiftBicCode",
    "type",
    "isSaved",
    "withdrawErr",
    "closeModal",
  ];

  method = null;
  status = null;
  withdrawMethod = 1;
  iti;
  intlTelInput = null;

  async connect() {
    this.intlTelInput = (await import("intl-tel-input")).default;
    const options = {
      // initialCountry: "auto",
      separateDialCode: true,
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js", // Load utils script

      initialCountry: "ua",
    };
    this.iti = this.intlTelInput(this.phoneTarget, options);

    axios(
      `/restrictions/location/get-countries?locale=${this.countryTarget.dataset.locale}`
    ).then(({ data }) => {
      const countries = data.map((item) => {
        return `<option value=${item.id}>${item.title}</option>`;
      });
      this.countryTarget.innerHTML += countries;
    });
    this.amountValueTarget.addEventListener("blur", (e) => {
      if (e.target.value < 100) {
        e.target.value = 100;
      }
      if (Number(e.target.value) > Number(e.target.dataset.max)) {
        e.target.value = e.target.dataset.max;
      }
    });
  }

  async getCities() {
    const firstOption = this.cityTarget.options[0].outerHTML;
    const { data } = await axios(
      `/restrictions/location/get-cities?country=${this.countryTarget.value}&locale=${this.countryTarget.dataset.locale}`
    );
    const cities = data.map((item) => {
      return `<option value=${item.id}
      }>${item.title}</option>`;
    });
    this.cityTarget.innerHTML = firstOption + cities;
    this.updateWithdrawBtn();
  }

  copyLink() {
    window.navigator.clipboard.writeText(this.refLinkTarget.innerText);
  }

  withdrawTab() {
    document.querySelector("#pills-profile-tab").click();
  }

  viewDetails(e) {
    const dataset = e.currentTarget.dataset;
    this.idTarget.innerText = dataset.id;
    this.amountTarget.innerText = dataset.amount;
    this.methodTarget.innerText = dataset.method;
    this.loginTarget.innerText = dataset.login;
    this.dateTarget.innerText = dataset.date;
    this.statusTarget.innerText = dataset.status;
    this.statusTarget.parentNode.style.backgroundColor =
      dataset.statusNum == 2 ? "#E5FAED" : "#F5F5F5";
    this.statusTarget.style.color =
      dataset.statusNum == 2 ? "#67AC5B" : "#8B929A";
  }

  filterMethod(e) {
    this.method = e.target.value;
    this.filterWithdraws();
  }

  filterStatus(e) {
    this.status = e.target.value;
    this.filterWithdraws();
  }

  filterWithdraws() {
    this.withdrawRowTargets.forEach((item) => {
      item.classList.remove("d-none");
    });

    this.withdrawRowTargets.forEach((item) => {
      if (this.method && item.dataset.method != this.method) {
        item.classList.add("d-none");
      }
      if (this.status && item.dataset.status != this.status) {
        item.classList.add("d-none");
      }
    });
  }

  selectWithdrawMethod(e) {
    this.withdrawMethod = Number(e.target.value);
    if (e.target.value == 1) {
      this.payoneerMethodTarget.classList.remove("d-none");
      this.swiftMethodTarget.classList.add("d-none");
    } else if (e.target.value == 2) {
      this.payoneerMethodTarget.classList.add("d-none");
      this.swiftMethodTarget.classList.remove("d-none");
    }
    this.updateWithdrawBtn();
  }

  amountHandle(e) {
    const inputValue = e.target.value;
    const digitsOnly = inputValue.replace(/\D/g, "");
    e.target.value = digitsOnly.toString();
  }

  updateWithdrawBtn() {
    const phonePattern = /^[0-9]+$/;
    if (
      (this.withdrawMethod === 1 &&
        validEmail.test(this.emailTarget.value) &&
        phonePattern.test(this.phoneTarget.value) &&
        this.countryTargets.every((el) => el.value !== "") &&
        this.cityTarget.value &&
        this.payoneerRecipientAddressTarget.value &&
        this.payoneerRecipientIndexTarget.value &&
        this.payoneerAccountTypeTarget.value &&
        this.payoneerBankNameTarget.value &&
        this.payoneerIbanTarget.value &&
        this.payoneerNameTarget.value &&
        this.payoneerCardTarget.value) ||
      (this.withdrawMethod === 2 &&
        this.swiftRecipientNameTarget.value &&
        this.swiftRecipientAddressTarget.value &&
        this.swiftBankNameTarget.value &&
        this.swiftBankAddressTarget.value &&
        this.swiftIbanTarget.value &&
        this.swiftBicCodeTarget.value)
    ) {
      this.withdrawBtnTarget.removeAttribute("disabled");
    } else {
      this.withdrawBtnTarget.setAttribute("disabled", true);
    }
  }

  async withdrawHandler() {
    const formData = new FormData();
    formData.append("amount", parseFloat(this.amountValueTarget.value));
    formData.append("type", this.typeTarget.value);
    formData.append("saved", this.isSavedTarget.checked);

    if (Number(this.typeTarget.value) == 1) {
      formData.append("recipientEmail", this.emailTarget.value);
      formData.append("recipientPhone", this.iti.getNumber());
      formData.append("cityId", this.cityTarget.value);
      formData.append(
        "recipientAddress",
        this.payoneerRecipientAddressTarget.value
      );
      formData.append(
        "recipientAddressIndex",
        this.payoneerRecipientIndexTarget.value
      );
      formData.append(
        "recipientType",
        Number(this.payoneerAccountTypeTarget.value)
      );
      formData.append("bankName", this.payoneerBankNameTarget.value);
      formData.append("iban", this.payoneerIbanTarget.value);
      formData.append("recipientName", this.payoneerNameTarget.value);
      formData.append("payoneerAccount", this.payoneerCardTarget.value);
    } else {
      formData.append("recipientName", this.swiftRecipientNameTarget.value);
      formData.append(
        "recipientAddress",
        this.swiftRecipientAddressTarget.value
      );
      formData.append("bankName", this.swiftBankNameTarget.value);
      formData.append("bankAddress", this.swiftBankAddressTarget.value);
      formData.append("iban", this.swiftIbanTarget.value);
      formData.append("swiftBicCode", this.swiftBicCodeTarget.value);
    }
    try {
      const { data } = await axios.post(
        "/referrer/withdrawal/create",
        formData
      );
      if (data.error) {
        this.showErrorAlert(data.error);
        return;
      }
      this.closeModalTarget.click();
      window.location.reload();
    } catch (e) {}
  }

  showErrorAlert(message) {
    this.withdrawErrTarget.classList.remove("d-none");
    this.withdrawErrTarget.innerText = message;
    setTimeout(() => {
      this.withdrawErrTarget.classList.add("d-none");
      this.withdrawErrTarget.innerText = "";
    }, 5000);
  }
}
