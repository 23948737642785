import axios from "axios";

export class PricePlanService
{
    premiumYearPlanKey = 'premium_year';
    premiumMonthPlanKey = 'premium_month';
    liteYearPlanKey = 'lite_year';
    liteMonthPlanKey = 'lite_month';

    _plansData = {};

    /**
     * @return {void}
     */
    loadPriceData()
    {
        axios("/get-all-plans").then(({ data }) => {
            this._plansData = data;
        });
    }

    /**
     * @param {string} planKey
     * @param {string} currency
     * @return {number|null}
     */
    getPricePlanByCurrency(planKey, currency)
    {
        const planObject = this._plansData[planKey];
        if (!planObject) {
            return null;
        }

        for (const key in planObject) {
            const currencyObject = planObject[key];
            if (typeof currencyObject === 'object') {
                const planCurrency = currencyObject['currency'];
                const planPrice = currencyObject['amount'];
                if (typeof planCurrency === 'string' && typeof planPrice === 'number' && planCurrency === currency) {
                    return planPrice;
                }
            }
        }

        return null;
    }

    /**
     * @param {string} currency
     * @return {number|null}
     */
    getPremiumYearPriceByCurrency(currency)
    {
        return this.getPricePlanByCurrency(this.premiumYearPlanKey, currency);
    }

    /**
     * @param {string} currency
     * @return {number|null}
     */
    getPremiumMonthPriceByCurrency(currency)
    {
        return this.getPricePlanByCurrency(this.premiumMonthPlanKey, currency);
    }

    /**
     * @param {string} currency
     * @return {number|null}
     */
    getLiteYearPriceByCurrency(currency)
    {
        return this.getPricePlanByCurrency(this.liteYearPlanKey, currency);
    }

    /**
     * @param {string} currency
     * @return {number|null}
     */
    getLiteMonthPriceByCurrency(currency)
    {
        return this.getPricePlanByCurrency(this.liteMonthPlanKey, currency);
    }
}