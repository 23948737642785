import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["table", "pagination"];

  connect() {
    this.addEventListeners();
  }

  addEventListeners() {
    this.paginationTarget.addEventListener("click", this.handlePaginationClick.bind(this));
  }

  handlePaginationClick(event) {
    event.preventDefault();

    if (event.target.tagName === "A") {
      const url = event.target.getAttribute("href");

      axios.get(url)
        .then(response => {
          const tempElement = document.createElement("div");
          tempElement.innerHTML = response.data;

          const newTableBody = tempElement.querySelector("#userTable");
          const tableBody = this.tableTarget;
          tableBody.innerHTML = newTableBody.innerHTML;

          const paginationLinks = this.paginationTarget.querySelectorAll("a");
          paginationLinks.forEach(link => {
            link.classList.remove("active");
          });

          const newPaginationLinks = tempElement.querySelector(".navigation");
          this.paginationTarget.innerHTML = newPaginationLinks.innerHTML;

          const currentPage = tempElement.querySelector(".navigation .current");
          if (currentPage) {
            const activePageNumber = currentPage.innerText.trim();
            const activeLink = this.paginationTarget.querySelector(`a[data-page="${activePageNumber}"]`);
            if (activeLink) {
              activeLink.classList.add("active");
            }
          }
        })
        .catch(error => {
          console.error("Error:", error);
        });
    }
  }
}
