import axios from "axios";
import { Buffer } from "buffer";
import { validLinkPattern, validLinkProtocol } from "../RegExpValidations";

export const getBase64FromUrl = async (url) => {
  try {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  } catch (err) {
    console.error("Error fetching image:", err);
    return "";
  }
};

export const base64ToFile = (dataUrl, filename = "file") => {
  const arr = dataUrl.split(",");
  if (arr.length < 2) {
    return undefined;
  }
  const mimeArr = arr[0].match(/:(.*?);/);
  if (!mimeArr || mimeArr.length < 2) {
    return undefined;
  }
  const mime = mimeArr[1];
  const buff = Buffer.from(arr[1], "base64");
  return new File([buff], filename, { type: mime });
};

export const safariOrIphone =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(!window["safari"] || window.safari.pushNotification) ||
  /iPad|iPhone/i.test(navigator.platform) ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 0);

export const handleFilesUpdate = async (files, setFiles, dispatch) => {
  if (!files.length) {
    setFiles([]);
    return;
  }
  try {
    const { data } = await axios("/entry/file/storage_size");
    if (data.storageSize !== null) {
      let summarySize = files.reduce((ac, file) => (ac += file.fileSize), 0);
      if (summarySize + data.usedStorageSize >= data.storageSize) {
        dispatch();
        setFiles([]);
        return;
      }
      setFiles(files);
    } else {
      setFiles(files);
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleLink = (str, setValidLink, setLink) => {
  let string = str.trim();
  if (validLinkPattern.test(string) && string.length <= 255) {
    setValidLink(true);
    if (!/^.+:\/\//.test(string)) {
      string = `http://${string}`;
    }
  } else {
    setValidLink(false);
  }
  setLink(string);
};

export const getUrlExtension = (url) => {
  return url.split(/[#?]/)[0].split(".").pop().trim();
};

export const getFileFromUrl = async (fileUrl, fileName) => {
  const imgExt = getUrlExtension(fileUrl);
  const response = await fetch(fileUrl);
  const blob = await response.blob();
  const file = new File([blob], fileName + "." + imgExt, {
    type: blob.type,
  });
  return file;
};

export const isNoEditRoute =
  window.location.href.slice(
    window.location.href.lastIndexOf("/") + 1,
    window.location.href.length
  ) !== "edit";

export const convertImageToBase64 = async (url) => {
  if (url && url.includes("/qr/logo/")) {
    const data = await getBase64FromUrl(url);
    return data;
  } else {
    return url;
  }
};

export const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
    );

    componentImport()
      .then((component) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false");
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem("retry-lazy-refreshed", "true");
          return window.location.reload();
        }
        reject(error);
      });
  });
};

const loadImage = async (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = url;
    img.onload = () => resolve(img);
    img.onerror = () => resolve(null);
  });
};

export const getBase64PngFromBase64PSvg = async (urlStr) => {
  if (urlStr) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = await loadImage(urlStr);
    if (img === null) {
      canvas.width = 1;
      canvas.height = 1;
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, 1, 1);
    } else {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
    }
    return canvas.toDataURL();
  }
  return null;
};



export const getSerializedSVGwithAnyLogo = (serializedSVG) => {
  const regexImgTag = new RegExp(
    `<image\\s+([^>]*\\s)?href=["']?\\s*data:image[^"']*["']?([^>]*)/>`,
    "g"
  );
  const matches = serializedSVG.match(regexImgTag);
  const replacedImage = matches[0].replace("href", "xlink:href");
  return serializedSVG.replace(regexImgTag, replacedImage);
};

export const getCookie = (cookieName) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(cookieName + "=")) {
      return decodeURIComponent(cookie.substring(cookieName.length + 1));
    }
  }
  return null;
};

export const isValidLinkString = (str) => {
  const linkStr = str.trim();
  if (linkStr.startsWith("http")) {
    return validLinkPattern.test(linkStr.trim());
  } else {
    return validLinkProtocol.test(linkStr.trim());
  }
};