import {Controller} from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
    static targets = ["articlesList", "typeUrl", "categorySelect", "searchBtn", "searchField", "csrf_token", "all", "cases", "instructions", "features"]

    connect() {  
        let currentUrl = window.location.hash.substring(1);
        this.typeUrlTarget.dataset.url = currentUrl;
        this.setActiveTab(currentUrl);

        window.addEventListener("hashchange", () => {
          currentUrl = window.location.hash.substring(1);
          this.setActiveTab(currentUrl);
          if (currentUrl !== "") {
            document.cookie = "typeUrl=" + currentUrl + "; path=/;";
            this.loadArticles(currentUrl);
          }
        });

 
        document.cookie = "typeUrl=" + currentUrl || "all" + "; path=/;";
        this.loadArticles(currentUrl || "all");
        

        let searchField = document.querySelector('#searchField');

        if (searchField) {
            searchField.addEventListener("blur", function() {
                let input = document.querySelector("#searchedArticles");

                if (input) {
                    setTimeout(function() {
                        input.classList.add("d-none");
                    }, 2000);
                }
            });

            searchField.addEventListener("focus", function() {
                let input = document.querySelector("#searchedArticles");

                if (input) {
                    input.classList.remove("d-none");
                }
            });
        }
    }

    loadArticles(typeUrl) {
        let url = window.location.pathname;

        axios.post(url, {
            typeUrl: typeUrl,
            isSearch: 1
        })
            .then(response => {
                this.articlesListTarget.innerHTML = response.data;
                this.articlesListTarget
                  .querySelectorAll(".page-link")
                  .forEach((item) => {
                    if (item.href && window.location.hash) {
                      item.href = item.href + window.location.hash;
                    }
                  });
            })
            .catch(error => {
                console.error(error);
            });
    }

    searchArticle(searchQuery) {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = this.csrf_tokenTarget.value;
        let currentUrl = window.location.pathname.split('/');

        if (/^page-\d+$/.test(currentUrl[currentUrl.length - 1])) {
            currentUrl.pop();
        }

        let url = currentUrl.join('/');

        if (!searchQuery) {
            window.location.reload();
            return true;
        }

        axios.post(url + '/search-query', {
            searchQuery: searchQuery
        })
            .then(response => {
                this.articlesListTarget.innerHTML = response.data.data.content;

                if (response.data.articlesByView) {
                    this.searchFieldTarget.insertAdjacentHTML('afterEnd', response.data.articlesByView.content);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    onCategorySelect(event) {
        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + 60 * 60 * 1000);
        document.cookie = `typeUrl=${
          event.currentTarget.dataset.typeUrl
        }; expires=${expirationDate.toUTCString()}; path=/;`;

        const currentUrl = window.location.href;
        const baseUrl = currentUrl.replace(/\/page-\d+/, "");
        const newUrl =
          baseUrl.split("#")[0] +
          `${
            event.currentTarget.dataset.typeUrl
              ? `#${event.currentTarget.dataset.typeUrl}`
              : ""
          }`;

        window.location.replace(newUrl);
    }

    onSearchBtnClick(event) {
        event.preventDefault();
        let data = this.searchFieldTarget.value;
        this.searchArticle(data);
    }

    onSearchFieldKeyUp(event) {
        clearTimeout(this.typingTimer);
        let value = event.target.value;
        this.typingTimer = setTimeout(() => {
            this.searchArticle(value);
        }, 1000);
    }

    setActiveTab(url) {
        if (url === "all" || url === "") {
            this.allTarget.classList.add("active");
            this.casesTarget.classList.remove("active");
            this.instructionsTarget.classList.remove("active");
            this.featuresTarget.classList.remove("active");
        } else if (url === "cases") {
            this.allTarget.classList.remove("active");
            this.casesTarget.classList.add("active");
            this.instructionsTarget.classList.remove("active");
            this.featuresTarget.classList.remove("active");
        } else if (url === "instructions") {
            this.allTarget.classList.remove("active");
            this.casesTarget.classList.remove("active");
            this.instructionsTarget.classList.add("active");
            this.featuresTarget.classList.remove("active");
        } else if (url === "features") {
            this.allTarget.classList.remove("active");
            this.casesTarget.classList.remove("active");
            this.instructionsTarget.classList.remove("active");
            this.featuresTarget.classList.add("active");
        }
    }
}