import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  myQrCodesBtn(e) {
    const urlParams = new URLSearchParams(window.location.search);
    const isNavigationDisabled = urlParams.get("disable_navigation") === "1";

    if (isNavigationDisabled) {
      window.ReactNativeWebView?.postMessage("link_to_my_qr_codes");
    } else {
      window.location.href = e.target.dataset.href;
    }
  }
}
