import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["read_more", "skip_ad"];

  google = null;
  getUserGeo = null;
  entry_id = null;
  scanCoordinates = null;
  lat = null;
  lng = null;
  isExactRequired = null;
  locationEntryRestrictions = null;
  seconds = 5;
  docId = null;

  async connect() {
    this.google = (await import("../../../../public/assets/js/qr-generator/google.js?v   =02072021"));
    this.getUserGeo = (await import("../../../../public/assets/js/getUserGeo.js?v=08042022_1"));
    this.entry_id = document.querySelector("[data-entry_id]").getAttribute("data-entry_id");
    let urlParams = new URLSearchParams(window.location.search);
    this.lat = urlParams.get("lat");
    this.lng = urlParams.get("lng");

    if (this.lat && this.lng) {
      this.scanCoordinates = { "lat": this.lat, "lng": this.lng };
    }
    let locationEntryRestrictions = document.querySelector("[data-locationEntryRestrictions]");
    if (locationEntryRestrictions) {
      this.locationEntryRestrictions = 1;
    }
    if (this.locationEntryRestrictions) {
      if (navigator.geolocation) {
        let isExactRequiredElement = document.querySelector("[data-isExactRequired]");
        if (isExactRequiredElement) {
          this.isExactRequired = isExactRequiredElement.getAttribute("data-isExactRequired");
        }

        navigator.geolocation.getCurrentPosition((position) => {
            this.scanCoordinates = { "lat": position.coords.latitude, "lng": position.coords.longitude };
            this.collectStatistic(this.scanCoordinates);
          },
          (error) => {
            if (this.isExactRequired) {
              if (error.code === error.PERMISSION_DENIED) {
                window.location.href = "/restrictions/location/geo-restriction-denied";
              }
            }
            this.collectStatistic();
          });
      }
    } else {
      await this.collectStatistic(this.scanCoordinates);
    }
  }

  onRead() {
    let article = document.querySelector("#article");
    let readMoreBtn = this.read_moreTarget;

    if (article.style.height) {
      article.style = "";
      readMoreBtn.children[0].innerHTML = readMoreBtn.dataset.less;
      readMoreBtn.children[1].querySelector("img").style.transform = "rotate(180deg)";
    } else {
      article.style.height = "850px";
      article.style.overflowY = "hidden";
      article.scrollIntoView();
      readMoreBtn.children[0].innerHTML = readMoreBtn.dataset.more;
      readMoreBtn.children[1].querySelector("img").style.transform = "rotate(0deg)";
    }
  }

  async increaseSkipAd() {
    let refreshIntervalId = setInterval(() => {
      let skipAdBtn = this.skip_adTarget;
      if (skipAdBtn) {
        let docId = skipAdBtn.getAttribute("data-docid");
        if (docId) {
          clearInterval(refreshIntervalId);
          // axios.post(`/statistic/increase-skip-ad/${docId}`);
        }
      }
    }, 1000);
  }

  async collectStatistic(scanCoordinates = null) {
    let result = null;
    const formData = new FormData();
    formData.append("scanCoordinates", scanCoordinates);

    let urlObj = new URL(window.location.href);
    let params = new URLSearchParams(urlObj.search);

    if (params.has('isSkipStat')) {
      return result;
    }

    let { data } = await axios.post(`/statistic/collect/${this.entry_id}`, formData);

    if (data) {
      let skipAdBtn = this.skip_adTarget;
      if (skipAdBtn) {
        skipAdBtn.setAttribute("data-docid", data);
      }

      result = data;
      setInterval(() => {
        this.sayHi(this.entry_id, data);
      }, 1000);
    }

    return result;
  }

  sayHi(id, docId) {
    let secondsContainer = document.querySelector("#seconds");
    if (this.seconds === 0) {

      if (docId !== undefined) {
        setTimeout(() => {
          this.collectEarn(id, docId);
        }, 1);
      }
    } else if (seconds > 0) {
      secondsContainer.innerHTML = this.seconds.toString();
    }
    this.seconds--;
  }

  async collectEarn(id, docId) {
     await axios.post(`/statistic/user-earn/${id}/${docId}`);
  }
}