import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ['emailField', 'emailFieldLabel', 'emailFieldInput'];
    static classes = ['emailFieldActive', 'emailFieldLabelActive'];


    focusEmailField()
    {
        this.emailFieldTarget.classList.add(this.emailFieldActiveClass);
        this.emailFieldLabelTarget.classList.add(this.emailFieldLabelActiveClass);
    }

    focusoutEmailField()
    {
        if (this.emailFieldInputTarget.textContent.trim().length === 0) {
            this.emailFieldTarget.classList.remove(this.emailFieldActiveClass);
            this.emailFieldLabelTarget.classList.remove(this.emailFieldLabelActiveClass);
        }
    }

    formatEmails(e)
    {
        if (e.code === 'Space') {
            this.highlightEmails(e);
        } else if (e.keyCode === 13) {
            e.preventDefault();
        }
    }

    pasteEmails(e)
    {
        e.preventDefault();
        this.highlightEmails(e);
    }

    highlightEmails(e)
    {
        let emails = this.emailFieldInputTarget.textContent.trim();
        let emailsFromClipboard = e.clipboardData !== undefined ? (e.clipboardData || window.clipboardData).getData('text').trim() : '';
        emails = emails.concat(' ', emailsFromClipboard);
        if (emails.length !== 0) {
            this.emailFieldInputTarget.innerHTML = '';
            emails = emails.trim().split(/(\s+)/);
            emails.forEach(email => {
                if (email.trim().length !== 0) {
                    let formattedEmail = this.createFormattedEmail(email);
                    this.emailFieldInputTarget.appendChild(formattedEmail);
                }
            });
            let spaceElement = document.createElement('span');
            spaceElement.style.display = 'inline-block';
            spaceElement.innerHTML = '&nbsp;';
            spaceElement.contentEditable = 'true';
            this.emailFieldInputTarget.appendChild(spaceElement);

            let s = window.getSelection(),
                r = document.createRange();
            r.setStart(spaceElement, 0);
            r.setEnd(spaceElement, 0);
            s.removeAllRanges();
            s.addRange(r);
        }
    }

    createFormattedEmail(email)
    {
        let emailElement = document.createElement('span');
        emailElement.className = 'email';
        emailElement.innerHTML = email.trim() + '&nbsp;';
        emailElement.contentEditable = 'false';

        let closeIcon = document.createElement('i');
        closeIcon.classList.add('fa', 'fa-times', 'email-icon-close');
        closeIcon.addEventListener('click', () => {
            this.removeEmail(closeIcon)
        });
        emailElement.append(closeIcon);

        return emailElement;
    }

    removeEmailAction(e)
    {
        let target = e.currentTarget
        this.removeEmail(target)
    }

    removeEmail(target)
    {
        let currentEmail = target.parentElement;
        currentEmail.remove();
        let spanElements = this.emailFieldInputTarget.getElementsByTagName('span');
        let lastSpaceElement = spanElements[spanElements.length - 1];
        let s = window.getSelection(),
            r = document.createRange();
        r.setStart(lastSpaceElement, 0);
        r.setEnd(lastSpaceElement, 0);
        s.removeAllRanges();
        s.addRange(r);
    }
}