import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = [
    "message",
    "sendBtn",
    "message_type",
    "antispam_time",
    "antispam_time_min",
    "antispam_time_max",
    "antispam_honeypot",
    "alert",
    "file",
    "fileName",
    "fileSize",
    "uploadDiv",
    "infoUpload",
    "infoUploadDiv",
    "maxFileSize",
    "limitText",
  ];

  messageInput(e) {
    let maxFileSize = 1024 * 1024 * 20;
    const file = this.fileTarget.files[0] ?? "undefined";

    if (file !== "undefined") {
      let kb = file.size / 1024;
      this.fileNameTarget.innerHTML = file.name;
      this.fileSizeTarget.innerHTML = kb.toFixed(2) + " KB";
      this.uploadDivTarget.classList.add("d-none");
      this.infoUploadDivTarget.classList.remove("d-none");
      if (file.size > maxFileSize) {
        this.maxFileSizeTarget.classList.add("text-danger");
        this.maxFileSizeTarget.classList.remove("text-gray");
      }
    }

    if (e.target.id === "floatingTextarea2") {
      if (
        this.messageTarget.value.length < 50 ||
        this.messageTarget.value.length > 5000
      ) {
        this.limitTextTarget.classList.add("text-danger");
        this.limitTextTarget.classList.remove("text-gray");
      } else {
        this.limitTextTarget.classList.remove("text-danger");
        this.limitTextTarget.classList.add("text-gray");
      }

      this.limitTextTarget.innerHTML =
        this.messageTarget.value.length + "/5000";
    }

    this.sendBtnTarget.disabled =
      this.messageTarget.value.length < 50 ||
      this.messageTarget.value.length > 5000 ||
      (file !== "undefined" && file.size > maxFileSize);
  }

  async send(e) {
    e.preventDefault();

    const file = this.fileTarget.files[0] ?? "undefined";

    const formData = new FormData();
    formData.append("message_type", this.message_typeTarget.value);
    formData.append("message_text", this.messageTarget.value);
    if (file !== "undefined") {
      formData.append("file", file, file.name);
    }
    formData.append("antispam_time", this.antispam_timeTarget.value);
    formData.append("antispam_time_min", this.antispam_time_minTarget.value);
    formData.append("antispam_time_max", this.antispam_time_maxTarget.value);
    formData.append("antispam_honeypot", this.antispam_honeypotTarget.value);
    try {
      await axios.post(`/submit`, formData);
      this.messageTarget.value = "";
      this.fileTarget.value = "";
      this.sendBtnTarget.disabled = true;
      this.alertTarget.classList.remove("d-none");
      setTimeout(() => this.alertTarget.classList.add("d-none"), 5000);
    } catch (e) {}
  }

  async deleteFile(e) {
    e.preventDefault();
    this.fileNameTarget.innerHTML = "";
    this.fileSizeTarget.innerHTML = "";
    this.uploadDivTarget.classList.remove("d-none");
    this.infoUploadDivTarget.classList.add("d-none");
    this.maxFileSizeTarget.classList.remove("text-danger");
    this.maxFileSizeTarget.classList.add("text-gray");
    this.fileTarget.value = "";
  }

  closeAlert(e) {
    e.currentTarget.parentNode.classList.add("d-none");
  }

  openChat() {
    window.HelpCrunch && HelpCrunch("openChat");
  }
}
