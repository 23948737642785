import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static values = {
    newHome: Boolean,
  }
  validEmail = /^(\w{2,100})+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
  static targets = ["input", "message", "form"];

  async addEmail(e) {
    e.preventDefault();

    if (!this.validEmail.test(this.inputTarget.value)) {
      this.messageTarget.innerText = "Incorrect email address";
      this.inputTarget.classList.add("border-danger");
      return;
    }

    let page = e.view.window.location.pathname.replace(/^\//g, "");
    if (page === "") {
      page = "home";
    }
    const event = `${this.newHomeValue ? "new-" : ""}${page}/button/subscribe`;
    gtag("event", event, {
      event_category: page,
      event_label: event,
      value: 1,
    });

    this.messageTarget.innerText = "";
    this.inputTarget.classList.remove("border-danger");

    const formData = new FormData(this.formTarget);
    try {
      const { data } = await axios.post(e.params.url, formData);
      this.inputTarget.value = "";
      if (data?.mail_send === true) {
        this.messageTarget.innerText =
          "You have subscribed to the news. Confirm your email";
        dataLayer.push({
          event: `${this.newHomeValue ? "new-" : ""}subscribed_form_sent`,
        });
      }
      if (data?.subscribed === false) {
        this.messageTarget.innerText = "You are already subscribed";
      }
      if (data?.csrf_token === false) {
        this.messageTarget.innerText = "Invalid CSRF token";
      }
      setTimeout(() => (this.messageTarget.innerText = ""), 5000);
    } catch (error) {
      this.messageTarget.innerText =
        error.response.data?.title || "Something went wrong. Try again";
      setTimeout(() => (this.messageTarget.innerText = ""), 5000);
    }
  }

  handleInput() {
    this.messageTarget.innerText = "";
    this.inputTarget.classList.remove("border-danger");
  }
}
