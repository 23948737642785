import {ProductPriceEnum} from "./product_price_enum";

export class ProductPriceService
{
    /**
     * @param {string} currency
     * @return {void}
     */
    replaceCurrencyForPurchaseButton(currency)
    {
        const elements = document.getElementsByClassName(ProductPriceEnum.purchaseBtnClass);
        for (const element of elements) {
            const redirectUrl = element.getAttribute('href');
            if (!redirectUrl) {
                console.error('Link attribute not found for purchase button');
                continue;
            }

            const regex = /currency=([a-z]{3})/; // Регулярное выражение для поиска параметра currency
            const newUrl = redirectUrl.replace(regex, `currency=${currency}`);

            element.setAttribute('href', newUrl);
        }
    }

    /**
     * @param {number} premiumYearPrice
     * @param {number} premiumMonthPrice
     * @param {number} liteYearPrice
     * @param {number} liteMonthPrice
     * @param {CurrencyDTO} currency
     * @return {void}
     */
    replacePricingOnPage(
        premiumYearPrice,
        premiumMonthPrice,
        liteYearPrice,
        liteMonthPrice,
        currency,
    ) {
        this._setPriceByElementClass(ProductPriceEnum.premiumYearPriceTextClass,
            currency.symbol,
            premiumYearPrice
        );
        this._setPriceByElementClass(
            ProductPriceEnum.premiumMonthPriceTextClass,
            currency.symbol,
            premiumMonthPrice
        );
        this._setPriceByElementClass(
            ProductPriceEnum.liteYearPriceTextClass,
            currency.symbol,
            liteYearPrice
        );
        this._setPriceByElementClass(
            ProductPriceEnum.liteMonthPriceTextClass,
            currency.symbol,
            liteMonthPrice
        );
        this._setPriceByElementClass(
            ProductPriceEnum.premiumYearPerMonthPriceTextClass,
            currency.symbol,
            this.calcYearPricePerMonth(premiumYearPrice, currency.decimalSeparator)
        );
        this._setPriceByElementClass(
            ProductPriceEnum.liteYearPerMonthPriceTextClass,
            currency.symbol,
            this.calcYearPricePerMonth(liteYearPrice, currency.decimalSeparator)
        );
        this._setPriceByElementClass(
            ProductPriceEnum.premiumAmountSavingTextClass,
            currency.symbol,
            this.calcAmountSaving(premiumYearPrice, premiumMonthPrice, currency.decimalSeparator)
        );
        this._setPriceByElementClass(
            ProductPriceEnum.liteAmountSavingTextClass,
            currency.symbol,
            this.calcAmountSaving(liteYearPrice, liteMonthPrice, currency.decimalSeparator)
        );
    }

    /**
     * @param {number} price
     * @param {number} decimalSeparator
     * @return {number}
     */
    calcYearPricePerMonth(price, decimalSeparator)
    {
        return Number((price / 12).toFixed(decimalSeparator));
    }

    /**
     * @param {number} priceYearly
     * @param {number} priceMonthly
     * @param {number} decimalSeparator
     * @return {number}
     */
    calcAmountSaving(priceYearly, priceMonthly, decimalSeparator)
    {
        return Number(((priceMonthly * 12) - priceYearly).toFixed(decimalSeparator));
    }

    /**
     * @param {string} elementClass
     * @param {string} currency
     * @param {number} price
     * @return {void}
     */
    _setPriceByElementClass(elementClass, currency, price)
    {
        const elements = document.getElementsByClassName(elementClass);
        for (const element of elements) {
            element.textContent = `${currency + price}`;
        }
    }
}